import { AfterViewInit, Component, OnInit, ViewChild, ViewChildren, QueryList, TemplateRef, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { StorageKey, StorageService } from 'src/app/core/common/storage.service';
//import { AppSubHeaderComponent} from "src/app/shared/components/app-sub-header/app-sub-header.component";
import { ActivatedRoute, ChildActivationStart, NavigationStart, Params, RouteConfigLoadStart, Router, RoutesRecognized } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PagerService } from 'src/app/core/common/pager.service';
import { NotificationService } from 'src/app/core/services/common/notification.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { UserService } from 'src/app/core/services/users/user.service';
import { CandidateService } from 'src/app/core/services/candidates/candidate.service';
import { ClientService } from 'src/app/core/services/clients/client.service';
import { ProjectService } from 'src/app/core/services/projects/project.service';
import { environment } from './../../../../environments/environment';
import { TasksService } from 'src/app/core/services/tasks/tasks.service';
import { NotesService } from 'src/app/core/services/notes/notes.service';
import { MicrosoftEmailService } from 'src/app/core/services/microsoftEmail/microsoft-email.service';
import { dueDateNotPast } from '../date.validators';

@Component({
  selector: 'app-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css']
})
export class AppHeaderComponent implements OnInit {
  //@ViewChild(AppSubHeaderComponent) appSubHeaderComponent!: AppSubHeaderComponent;
  //@ViewChildren(AppSubHeaderComponent) moduleItems!:QueryList<AppSubHeaderComponent>
  CreateNoteForm !: FormGroup;
  saveId!: number
  tasklistlength: any;
  titleoftask: string = ""
  userList: any = [];
  userListtask: any = [];
  taskList: any = [];
  emailList: any = [];
  notesList: any = [];
  MSEmail: string = "";
  candidate_id: string = "";
  emailAuthorizationRedirectURL: string = "";
  emailMessageDetails: any;
  timeout: any = null;
  noResultFound: string = "";
  production_environment: boolean = true;
  uat_environment: boolean = false;
  avtarPath!: boolean;
  currentAvtar: any;
  isDropdownOpen: boolean = false;
  searchtext: any
  currentUsername: string | undefined = "";
  imgSrc: any;
  userid: any;
  userShortname!: string | null;
  dropimg: boolean = false;
  //modalService: any;
  sortcutName: boolean | undefined = false;
  iconVisible: boolean = false;
  toArray: any;
  initials!: string;
  sortname!: string;
  Candidates: any;
  Projects: any
  previewImg: boolean = true;
  files: any;
  filePath!: string;
  fileName!: string;
  url: any;
  defaultTaskStatus: any = "1";

  linkedin_submitted: boolean = false;
  manually_submitted: any = -1;
  //url2 = '../assets/img/avatar-placeholder.png';
  url3: number | any = 1;
  EditId!: number;
  clientsList = [];
  options = [
    { id: 1, name: 'Option 1' },
    { id: 2, name: 'Option 2' },
    { id: 3, name: 'Option 3' },
    // ...
  ];
  CreateUserForm!: FormGroup
  closeResult!: string;
  CreateCandidateForm!: FormGroup;
  CreateCandidateManuallyForm !: FormGroup;
  CreateClientForm!: FormGroup;
  CreateTaskForm!: FormGroup;
  createTaskError: any;
  CreateProjectForm!: FormGroup;
  nextTaskListUrl: string = "";
  createCandidateError: any
  submitted: boolean = false;
  createUserError: string = ""
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  userprofile = [];
  createCandidateData: any;
  createClientData: any;
  createClientError: any;
  createProjectError: any;
  projectList: any;
  userProjectList: any;
  candidateProjectList: any;
  clientList: any;
  projectTypeList: any;
  createProjectTypeError: any;
  createProjectData: any;
  createTaskData: any;
  createProjectClientError: any;
  projectapicall: boolean = false;
  taskapicall: boolean = false;
  noTasksFound: string = "";
  createUserErrorAvatar: any;
  createUserErrorName: any;
  createUserErrorPassword: any;
  createCandidateErrorUrl: any;
  createCandidateErrorProjectId: any;
  createProjectErrorName: any;
  createProjectErrorDescription: any;
  createProjectErrorClientname: any;
  createProjectErrorOfficialstartdate: any;
  createProjectErrorType: any;
  createProjectErrorCity: any;
  createClientErrorName: any;
  createClientErrorIndustry: any;
  createClientErrorCity: any;
  showPassword: boolean = false;
  editedInitials: any;
  startingInitials: any;
  userinitials: any;
  createCandidateManuallyData: any;
  createCandidateManuallyConfirmation: boolean = false;
  createCandidateManuallyError: any;
  candidate_exists: any;
  linkedin_candidate: boolean = true;
  tenantName: any;
  tenantRole: any;
  tenantNameshow1: boolean = false;
  tenantName1: any;
  tenantName2: any;
  tenantNameshow2: boolean = false;
  tenantlogin: any = "1";
  is_Super_Admin: any;
  tenant: any;
  typingTimer: any;                //timer identifier
  doneTypingInterval = 1000;
  todayDate: any;
  taskId: any;
  createTaskErrorTitle: any;
  createTaskErrorDescription: any;
  createTaskErrorIsactive: any;
  createTaskErrorSearchid: any;
  createTaskErrorAssigned_to: any;
  createTaskErrorend_date: any;
  selectDeletedTag: any;
  selectDeletedTask!: any;
  CreatNotesError: any;
  CreatNotesErrorProjectid: any;
  CreatNotesErrorNote: any;
  CreatNotesErrorAllowexport: any;
  emailBodyPreview: string = "";
  // taskId:any;
  taskDueCount: any = 0;
  mailInboxCount: any = 0
  taskListActiveType: string = 'due';
  loadTaskTillPage = 1;
  taskAssigneeName: any;
  showEmailList: boolean = false;
  emailListActiveFolderName: string = 'inbox';
  currentURL: any;
  @ViewChild('formDirective')
  private formDirective!: NgForm;
  @ViewChild('EmailPopup') emailpopup: ElementRef<HTMLDivElement> | any;
  @ViewChild('loginWithEmail') loginWithEmail: ElementRef<HTMLDivElement> | any;
  @ViewChild('emailDetails') emailDetails: ElementRef<HTMLDivElement> | any;
  noEmailsFound: string = "";
  emailapicall: boolean = false;

  TaskForm!: FormGroup;
  candidatedetails: any;
  is_in_personal_email: any;
  in_work_email: any

  userRole: any;
  userRoleshow: boolean = false;
  datafornotes: any;
  testing: any;
  is_client_module_access_disabled: any


  constructor(private authServcie: AuthService,
    private authservice: AuthService,
    private clientService: ClientService,
    private projectService: ProjectService,
    private taskService: TasksService,
    private storageService: StorageService,
    private userservice: UserService,
    private notificationService: NotificationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private candidateService: CandidateService,
    private userService: UserService,
    private notesService: NotesService,
    private microsoftService: MicrosoftEmailService,
    private route: ActivatedRoute
  ) {
    this.production_environment = environment.production;
    this.uat_environment = environment.uat;
    //this.todayDate = moment().format('DD/MM/YYYY');
    let MSAuthorized = this.route.snapshot.params['authorized'];

    this.currentURL = window.location.pathname.split('/');

    this.candidate_id = this.currentURL[this.currentURL.length - 1];
    this.showEmailList = (this.currentURL[this.currentURL.length - 2] == 'candidate');
    this.is_client_module_access_disabled = this.storageService.getValue('is_client_module_access_disabled')


    router.events.subscribe((event) => {
      if (event instanceof RoutesRecognized) {
        this.currentURL = event.url.split('?')[0].split('/');
        this.candidate_id = this.currentURL[2];
        var candidate_id = this.candidate_id;
        if (candidate_id)
          this.getcandidate_details(parseInt(candidate_id))
      }
      if (event instanceof ChildActivationStart) {
        let path = event.snapshot.routeConfig?.path;

        this.showEmailList = (path == 'candidate');
      }
    });

    this.route.queryParams.subscribe((params: any) => {
      if (params.authorized && this.showEmailList) {
        this.checkEmailAuth();
      }
    });

    this.TaskForm = this.formBuilder.group({
      userId: [null]

    });


  }
  get createCandidateForm() { return this.CreateCandidateForm.controls; }
  get createCandidateManuallyForm() { return this.CreateCandidateManuallyForm.controls; }
  get createProjectForm() { return this.CreateProjectForm.controls; }
  get createClientForm() { return this.CreateClientForm.controls; }
  get createTaskForm() { return this.CreateTaskForm.controls; }
  get createNoteForm() { return this.CreateNoteForm.controls; }

  ngOnInit(): void {
    this.currentuser();
    this.getUserProfile();

    this.onLoad();
    this.CreateNoteForm = this.formBuilder.group({
      notes_project: [null],
      notes: [null, [Validators.required]],
      allow_export: [null],
      ms_mail_id: [null],
      ms_mail_received_at: [null]
    });
    // this.authServcie.tenantName.subscribe((data)=>{
    //   this.tenant=data;
    //   console.log(this.tenant,"hj");

    // })


    let currentTenantname = this.storageService.getValue(StorageKey.currentTenantnameId);
    this.tenantName = currentTenantname;

    let currentTenantrole = this.storageService.getValue(StorageKey.currentTenantId);
    this.tenantRole = currentTenantrole;
    // console.log(this.tenantRole, "Header Line 270")

    if (this.tenantRole == 0) {
      this.tenantNameshow1 = true;
      this.tenantName1 = currentTenantname;
    } else if (this.tenantRole != 0) {
      this.tenantNameshow2 = true;
      this.tenantName2 = currentTenantname;
    }

    let currentUserrole = this.storageService.getValue(StorageKey.currentUserRoleId);
    this.userRole = currentUserrole;
    // console.log(this.userRole, "Current User Role")
    if (this.userRole == 1) {
      this.userRoleshow = true;
    } else if (this.userRole == 2) {
      this.userRoleshow = false;
    }


    if (this.candidate_id)
      this.getcandidate_details(parseInt(this.candidate_id))

  }

  onLoad() {
    this.onformBuild();

    this.is_Super_Admin = Number(localStorage.getItem('superadminflag'));
    this.tenant = (localStorage.getItem('tenant'));
    this.getUserList();
    this.getTaskList();
    this.getUserListtask();

  }

  onformBuild() {
    this.CreateCandidateForm = this.formBuilder.group({
      linkedInUrl: [null, [Validators.required]],
      projectId: [null]
    });

    this.CreateCandidateManuallyForm = this.formBuilder.group({
      firstname: [null, [Validators.required]],
      lastname: [null, [Validators.required]],
      email: [null, [Validators.pattern(this.emailPattern)]],
      projectId: [null]
    });

    this.CreateClientForm = this.formBuilder.group({
      ClientId: [null],
      name: [null, [Validators.required]],
      industry: [null],
      city: [null],
      state: [null],
      email: [null],
      contactNumber: [null],
      postalCode: [null],
      StreetAddress: [null],
      description: [null],
      website: [null],
      user_id: [null, [Validators.required]],

    })

    this.CreateTaskForm = this.formBuilder.group({
      TaskId: [null],
      title: [null, [Validators.required]],
      description: [null],
      // endDate: [null, [Validators.required]],
      endDate: [null, [Validators.required, dueDateNotPast()]],
      assignedTo: [null, [Validators.required]],
      searchId: [null],
      status: [1, [Validators.required]],
      reminder: [null]
    });

    this.CreateProjectForm = this.formBuilder.group({
      projectName: [null, [Validators.required]],
      description: [null],
      // startDate: [null],
      startDate: [null, [Validators.required]],
      endDate: [null],
      client: [null],
      projectType: [null,],
      city: [null],

    });
  }

  getCandidateProjectList(term: any = null) {

    let searchProject = "";

    if (term) {
      searchProject = term.term;
    }


    this.candidateProjectList = [];

    if (this.candidate_id) {
      this.candidateService.showProjectsCandidatewise(Number(this.candidate_id)).subscribe({
        next: (response) => {
          console.log(response);
          this.candidateProjectList = response.projects;
          //this.userProjectList = this.projectList.filter((ele:any) => ele.project_status_id == 1) ;
          this.candidateProjectList.forEach((ele: any) => { ele["dropdown"] = ele?.name + (ele.client?.name ? " [" + ele.client?.name + "]" : "") })
          this.candidateProjectList.unshift({ id: 0, project_id: 0, dropdown: "General" });
        },
        error: (error) => {

        }
      });
    }
  }

  getUserProjectList(term: any = null) {
    //  this.projectselect?.clearModel();
    // this.showProjectDropdown=true;
    let searchProject = "";
    this.createTaskForm['searchId'].reset();
    if (term) {
      searchProject = term.term;
    }

    if (this.createTaskForm['assignedTo'].value) {
      let userId = this.createTaskForm['assignedTo'].value;
      this.userProjectList = [];

      if (userId) {
        this.projectService.listByFilter({ user_id: userId, search: searchProject }).subscribe({
          next: (response) => {

            this.userProjectList = response.projects.data;
            //this.userProjectList = this.projectList.filter((ele:any) => ele.project_status_id == 1) ;
            this.userProjectList.forEach((ele: any) => { ele["dropdown"] = ele?.name + (ele.client?.name ? " [" + ele.client?.name + "]" : "") })
            this.userProjectList.unshift({ id: 0, project_id: 0, dropdown: "General" });
          },
          error: (error) => {

          }
        });
      }
    }
  }

  getProjectList() {
    //  this.projectselect?.clearModel();
    // this.showProjectDropdown=true;
    this.projectService.listAll().subscribe({
      next: (response) => {
        this.projectList = response.projects.data;
        this.projectList = this.projectList.filter((ele: any) => ele.project_status_id == 1);
        this.projectList.forEach((ele: any) => { ele["dropdown"] = ele?.name + (ele.client?.name ? " [" + ele.client?.name + "]" : "") })
        this.projectList.unshift({ id: 0, project_id: 0, dropdown: "General" });
      },
      error: (error) => {

      }
    });
  }
  setupCreateProjectForm() {
    this.getProjectList();
    this.getClientsList();
    this.getClientList();
    this.getProjectTypeList();


  }
  getProjectTypeList() {
    this.projectService.listTypeAll().subscribe({
      next: (response) => {
        this.projectTypeList = response.project_types;

      },
      error: (error) => {
        this.createProjectTypeError = error.error.message;
        this.createProjectTypeError = error.error.url;
        this.notificationService.showError(error.message);
      }
    });
  }

  onProjectSearch(term: any) {
    let searchValue = term.term;
    // console.log(searchValue);
    //searchValue.length>=2
    if (true) {
      let data = { search: searchValue };
      this.projectService.searchProjectwiseList(data).subscribe({
        next: (response: any) => {
          // console.log(response);
          this.projectList = response.projects.data;
          this.projectList = this.projectList.filter((ele: any) => ele.project_status_id == 1);
          this.projectList.forEach((ele: any) => { ele["dropdown"] = ele?.name + (ele.client?.name ? " [" + ele.client?.name + "]" : "") })
        },
        error: (err) => {

        }
      })
    }
  }

  getClientList() {
    this.clientService.listAll().subscribe({
      next: (response) => {
        this.clientList = response.clients.data;
      },
      error: (error) => {
        this.createProjectClientError = error.error.message;
        this.createProjectClientError = error.error.url;
        this.notificationService.showError(error.message);
      }
    });
  }


  createCandidate() {

    this.linkedin_submitted = true;
    if (this.CreateCandidateForm.invalid) {
      return;
    }
    // if (this.CreateCandidateForm.invalid){
    //   this.createCandidateError = 'Enter Valid Data';
    //   return;
    // }


    // if (this.createCandidateForm['linkedInUrl'].value.trim() == "") {
    //   this.notificationService.showError('Please Enter LinkedIn URL');
    //   this.createCandidateError = 'Please Enter LinkedIn URL';
    //   return;
    // }

    this.createCandidateData = {
      url: this.createCandidateForm['linkedInUrl'].value
    }

    if (this.createCandidateForm['projectId'].value) {
      this.createCandidateData.project_id = this.createCandidateForm['projectId'].value;
    }
    this.projectapicall = true;
    this.candidateService.create(this.createCandidateData).subscribe({
      next: (response) => {
        this.modalService.dismissAll();
        this.CreateCandidateForm.reset();
        this.notificationService.showSuccess(response.message);
        this.projectapicall = false;
        this.linkedin_submitted = false;
        // window.location.reload();

      },
      error: (error) => {
        this.createCandidateError = error.error.message;
        this.projectapicall = false;
        switch (error.status) {
          case 422:
            if (error.error.url) {
              this.createCandidateErrorUrl = error.error.url[0];
            }
            if (error.error.projectId) {
              this.createCandidateErrorProjectId = error.error.projectId[0];
            }
            break;
          default:
          //Nothing
        }
        //  this.notificationService.showError(error.message);
      }
    });
  }

  candidateTypeChange(linkedin_candidate: boolean) {
    this.linkedin_candidate = linkedin_candidate;

  }

  createCandidateManually() {
    this.submitted = true;
    if (this.CreateCandidateManuallyForm.invalid) {
      return;
    }

    this.createCandidateManuallyData = {
      in_first_name: this.createCandidateManuallyForm['firstname'].value,
      in_last_name: this.createCandidateManuallyForm['lastname'].value,
      in_personal_email: this.createCandidateManuallyForm['email'].value,
      confirmed: (this.createCandidateManuallyConfirmation) ? 1 : 0
    }

    if (this.createCandidateManuallyForm['projectId'].value) {
      this.createCandidateManuallyData.project_id = this.createCandidateManuallyForm['projectId'].value;
    }
    this.projectapicall = true;
    this.candidateService.createManually(this.createCandidateManuallyData).subscribe({
      next: (response) => {
        this.manually_submitted = response.status;
        if (response.status == 0) {
          this.projectapicall = false;
          this.createCandidateManuallyError = response.message;
          this.candidate_exists = response.candidates;
        } else {
          this.modalService.dismissAll();
          this.CreateCandidateManuallyForm.reset();
          this.notificationService.showSuccess(response.message);
          this.router.navigate(['/candidate/' + response.candidate.id]);
          this.projectapicall = false;
          this.submitted = false;
          // this.getCandidateList();
        }
      },
      error: (error) => {
        this.createCandidateError = error.error.message;
        this.projectapicall = false;
        switch (error.status) {
          case 422:
            if (error.error.url) {
              this.createCandidateErrorUrl = error.error.url[0];
            }
            if (error.error.projectId) {
              this.createCandidateErrorProjectId = error.error.projectId[0];
            }

            break;
          default:
          //Nothing
        }
        // this.notificationService.showError(error.message);
      }
    });
  }
  createClients() {

    this.submitted = true;

    if (this.CreateClientForm.invalid) {
      return;
    }

    // if (this.createClientForm['name'].value.trim() == "") {
    //   this.notificationService.showError('Please Enter name');
    //   this.createClientError="Please Enter Name";
    //   return;
    // }
    // if (this.createClientForm['industry'].value.trim() == "") {
    //   this.notificationService.showError('Please Enter industry');
    //   this.createClientError="Please Enter Industry";
    //   return;
    // }
    // if (this.createClientForm['city'].value.trim() == "") {
    //   this.notificationService.showError('Please Enter city');
    //   this.createClientError="Please Enter City";
    //   return;
    // }

    this.createClientData = {
      name: this.createClientForm['name'].value,
      industry: this.createClientForm['industry'].value,
      // city: this.createClientForm['city'].value,
      user_id: this.CreateClientForm.controls['user_id'].value,

    }
    this.projectapicall = true;
    this.clientService.create(this.createClientData).subscribe({
      next: (response: any) => {
        this.modalService.dismissAll();
        this.CreateClientForm.reset();
        this.notificationService.showSuccess(response.message);
        this.projectapicall = false;
        this.submitted = false;
        window.location.reload();
      },
      error: (error: any) => {
        this.createClientError = error.error.message;
        this.projectapicall = false;
        switch (error.status) {
          case 422:
            if (error.error.name) {
              this.createClientErrorName = error.error.name[0];
            }
            if (error.error.industry) {
              this.createClientErrorIndustry = error.error.industry[0];
            }

            if (error.error.city) {
              this.createClientErrorCity = error.error.city[0];
            }

            break;
          default:
          //Nothing
        }
        // this.notificationService.showError(error.message);
      }
    });

  }
  createProject() {

    this.submitted = true;

    if (this.CreateProjectForm.invalid) {
      return;
    }

    this.createProjectData = {
      name: this.createProjectForm['projectName'].value,
      description: this.createProjectForm['description'].value,
      client_id: this.createProjectForm['client'].value,
      project_type_id: this.createProjectForm['projectType'].value,
      project_status_id: 1,
      city: this.createProjectForm['city'].value,
      is_public: 0
    }

    if (this.createProjectForm['startDate'].value) {
      this.createProjectData.official_start_date = this.createProjectForm['startDate'].value;
    }

    if (this.createProjectForm['endDate'].value) {
      this.createProjectData.official_end_date = this.createProjectForm['endDate'].value;
    }
    this.projectapicall = true;
    this.projectService.create(this.createProjectData).subscribe({
      next: (response: any) => {
        this.modalService.dismissAll();
        this.CreateProjectForm.reset();
        this.notificationService.showSuccess(response.message);
        //  window.location.reload();
        this.projectapicall = false;
        this.submitted = false;
        this.getProjectList();
      },
      error: (error: any) => {
        this.createProjectError = error.error.message;
        this.projectapicall = false;
        switch (error.status) {
          case 422:
            if (error.error.name) {
              this.createProjectErrorName = error.error.name[0];
            }
            if (error.error.description) {
              this.createProjectErrorDescription = error.error.description[0];
            }
            if (error.error.client_id) {
              this.createProjectErrorClientname = error.error.client_id[0];
            }
            if (error.error.official_start_date) {
              this.createProjectErrorOfficialstartdate = error.error.official_start_date[0];
            }
            if (error.error.type) {
              this.createProjectErrorType = error.error.type[0];
            }
            if (error.error.city) {
              this.createProjectErrorCity = error.error.city[0];
            }
            break;
          default:

          //Nothing

        }

        // this.notificationService.showError(error.message);
      }
    });

  }


  currentuser() {
    //  let currentAvtar = this.storageService.getValue(StorageKey.currentAvtar);
    //  this.imgSrc = currentAvtar;

    // let currentUserid = this.storageService.getValue(StorageKey.currentUserid);
    //  this.userid = currentUserid;
    //   if(currentAvtar == 'null'){
    //     this.avtarPath = false;
    //   }
    //  else{
    //       this.avtarPath = true;

    //     }

    // var currentUsername = this.storageService.getValue(StorageKey.currentUsername);
    //  this.userShortname = currentUsername;
    //  const word = this.userShortname;
    //  if(word != null){
    //   const wordAsString = word.toString();
    //   this.getInitials(wordAsString);
    // const initials = this.getInitials(wordAsString);
    // this.sortname = initials;


    //  }


  }

  createTask() {
    this.submitted = true;
    if (this.CreateTaskForm.invalid)
      return;

    this.createTaskData = {
      title: this.createTaskForm['title'].value,
      description: this.createTaskForm['description'].value,
      search_id: this.createTaskForm['searchId'].value,
      assigned_to: this.createTaskForm['assignedTo'].value,
      status: this.createTaskForm['status'].value
    }

    if (this.createTaskForm['endDate'].value) {
      this.createTaskData.end_date = this.createTaskForm['endDate'].value;
    }

    this.taskService.create(this.createTaskData).subscribe({
      next: (response: any) => {
        this.modalService.dismissAll();
        this.CreateTaskForm.reset();
        this.notificationService.showSuccess(response.message);
        //  window.location.reload();
        //this.projectapicall=false;
        this.submitted = false;
        this.getTaskList(this.taskListActiveType);

        // this.getProjectList();
      },
      error: (error: any) => {
        this.createTaskError = error.error.message;
        switch (error.status) {
          case 422:
            if (error.error.title) {
              this.createTaskErrorTitle = error.error.title[0];
            }
            if (error.error.description) {
              this.createTaskErrorDescription = error.error.description[0];
            }
            if (error.error.status) {
              this.createTaskErrorIsactive = error.error.status[0];
            }
            if (error.error.search_id) {
              this.createTaskErrorSearchid = error.error.search_id[0];
            }
            if (error.error.assigned_to) {
              this.createTaskErrorAssigned_to = error.error.assigned_to[0];
            }
            if (error.error.end_date) {
              this.createTaskErrorend_date = error.error.end_date[0];
            }
            break;
          default:
          //Nothing
        }
      }
    });
  }


  getInitials(name: string): string {
    const words = name.split(' ');
    let initials = '';

    for (let i = 0; i < words.length; i++) {
      const word = words[i].trim();

      if (word.length > 0) {
        initials += word[0].toUpperCase();
      }
    }

    return initials;
  }

  logout() {
    this.authServcie.sign_out().subscribe({
      next: (responce: any) => {
        this.storageService.removeValue(StorageKey.currentTenantId);
        this.authServcie.logout();
        this.router.navigate(['']);

      },
      error: (err: any) => {

      }
    })


  }

  get f() { return this.CreateUserForm.controls; }
  //get f2() { return this.createTaskForm.controls; }

  generateForm() {
    this.CreateUserForm = this.formBuilder.group({
      UserId: [null],
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.pattern(this.emailPattern)]],
      password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      role_id: [null, [Validators.required]],
      is_active: [null],
      avatar: [null],
    })
  }

  getUserProfile() {
    let currentTenant = this.storageService.getValue(StorageKey.currentTenantId);
    this.tenantlogin = currentTenant;
    // console.log(this.tenantlogin, "Header")
    //  if(currentTenant == '0'){
    //   return 
    //  }

    this.userservice.userProfile(currentTenant).subscribe({
      next: (response) => {
        this.userprofile = response.user;
        this.imgSrc = response.user.avatar_path;
        //  console.log(this.imgSrc,"imgsrc")
        //    let currentAvtar;
        //    currentAvtar =  this.imgSrc;
        //   if(currentAvtar == 'null'){
        //     this.avtarPath = false;
        //   }
        //  else{
        //       this.avtarPath = true;

        //     }

        response["initials"] = this.getInitials(response.user.name);
        this.userinitials = response.initials;

        //  console.log(response);
        //    console.log( this.userinitials);
        var currentUsername = response.user.name;
        //  this.userShortname = currentUsername;
        //   const word = this.userShortname;
        //   if(word != null){
        //    const wordAsString = word.toString();
        //    this.getInitials(wordAsString);
        //  const initials = this.getInitials(wordAsString);
        //  this.sortname = initials;


        //   }

      },
      error: (error: any) => {
        // this.notificationService.showError(error.message);
      }
    });
  }


  onblur() {
    this.Candidates = [];
    this.Projects = [];
  }


  // //on keyup, start the countdown
  // startSearching(event:any) {
  //   this.searchtext = event.target.value;
  //   //clearTimeout(this.typingTimer);
  //   console.log(this.searchtext)
  //   this.typingTimer = setTimeout(this.globalSearch, this.doneTypingInterval);
  // }

  // //on keydown, clear the countdown 
  // stopSearching(event:any) {
  //  /*  this.searchtext = event.target.value;
  //   console.log(this.searchtext) */
  //  clearTimeout(this.typingTimer);
  // }

  //user is "finished typing," do something
  /* function doneTyping () {
    //do something
  }
   */

  onKeySearch(event: any) {
    this.noResultFound = "";
    clearTimeout(this.timeout);

    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode) {
        $this.globalSearch(event.target.value);
      }
    }, 1000);
  }

  globalSearch(searchText: string = "") {
    // this.isDropdownOpen = !!this.searchtext.trim();
    this.searchtext = searchText;
    this.noResultFound = searchText;
    let searchValue = this.searchtext;
    if (searchValue == "" || searchValue == null) {
      this.Candidates = [];
      this.Projects = [];
      return;
    }

    let data = { search: searchValue };
    this.candidateService.userProjectList(data).subscribe({
      next: (response: any) => {
        this.Candidates = response.candidates;
        this.Projects = response.projects;
        this.Projects.forEach((ele: any) => { ele["dropdown"] = ele?.name + (ele.client?.name ? " [" + ele.client?.name + "]" : "") })
      },
      error: (err) => {

      }
    })

  }
  redirectToCandidate(id: any) {
    this.router.navigate(['/candidate/' + id]);
    setTimeout(() => {
      window.location.reload();
    }, 500);

  }
  redirectToProject(id: any) {
    this.router.navigate(['/search/' + id]);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }


  EditProfile(content: any) {
    let currentTenant = this.storageService.getValue(StorageKey.currentTenantId);
    this.userservice.userProfile(currentTenant).subscribe((data) => {
      let response = data.user
      this.url = data.user.avatar_path;
      response["initial"] = this.getInitials(response.name);
      this.editedInitials = response.initial;
      if (this.url == null) {
        this.previewImg = false;
        this.dropimg = true;
      } else if (this.url != null) {
        this.previewImg = true;
        this.dropimg = false;
      }

      this.CreateUserForm.controls['name'].setValue(response.name);
      this.CreateUserForm.controls['password'].setValue(response.password);

      // this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'md'}).result.then((result) => {
      //   this.closeResult = `Closed with: ${result}`;
      // }, (reason) => {
      //   this.closeResult = `Dismissed `;
      // });

    })

  }

  EditTask(content: any, EditId: any) {
    if (EditId != undefined || EditId != null) {
      this.taskService.getTaskData({ id: EditId }).subscribe((data) => {
        let response = data.task
        this.CreateTaskForm.controls['TaskId'].setValue(response.id);
        this.CreateTaskForm.controls['title'].setValue(response.title);
        this.CreateTaskForm.controls['description'].setValue(response.description);
        this.getUserList({ term: response.assignee.name });
        this.CreateTaskForm.controls['assignedTo'].setValue(response.assigned_to);

        this.getUserProjectList();
        this.CreateTaskForm.controls['searchId'].setValue(response.search_id);

        this.CreateTaskForm.controls['status'].setValue(response.status);
        this.CreateTaskForm.controls['endDate'].setValue(response.end_date);
        this.CreateTaskForm.controls['reminder'].setValue(response.reminder);


        // this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'md'}).result.then((result) => {
        //   this.closeResult = `Closed with: ${result}`;
        // }, (reason) => {
        //   //${this.getDismissReason(reason)}
        //   this.closeResult = `Dismissed `;
        // });

      })
    }
  }

  UpdateTasks() {
    this.submitted = true;
    if (this.CreateTaskForm.invalid) {
      return;
    }


    // if (this.f['name'].value.trim() == "") {
    //   this.notificationService.showError('Please enter name');
    //   return;
    // }
    // if (this.f['description'].value.trim() == "") {
    //   this.notificationService.showError('Please enter description');
    //   return;
    // }

    let UpdatedTaskDetails = {
      id: this.CreateTaskForm.controls['TaskId'].value,
      title: this.CreateTaskForm.controls['title'].value,
      description: this.CreateTaskForm.controls['description'].value,
      search_id: this.CreateTaskForm.controls['searchId'].value,
      assigned_to: this.createTaskForm['assignedTo'].value,
      status: this.createTaskForm['status'].value,
      end_date: this.createTaskForm['endDate'].value,
      reminder: this.createTaskForm['reminder'].value,
    };

    this.projectapicall = true;
    this.taskService.updateTaskData(UpdatedTaskDetails.id, UpdatedTaskDetails).subscribe({
      next: (response) => {
        //console.log("responce 2000",response)
        this.modalService.dismissAll();
        this.CreateTaskForm.reset();
        this.notificationService.showSuccess(response.message);
        //  window.location.reload();
        this.projectapicall = false;
        this.submitted = false;
        this.getTaskList(this.taskListActiveType);
        /* for (let i = 0; i < this.taskList.length; i++) {
          if (this.taskList[i].id === UpdatedTaskDetails.id) {
            this.taskList[i] = response.task;
            break; 
          }
        } */
      },
      error: (error) => {
        this.projectapicall = false;
        this.createTaskError = error.error.message;
        switch (error.status) {
          case 422:
            if (error.error.title) {
              this.createTaskErrorTitle = error.error.title[0];
            }
            if (error.error.description) {
              this.createTaskErrorDescription = error.error.description[0];
            }
            if (error.error.status) {
              this.createTaskErrorIsactive = error.error.status[0];
            }
            if (error.error.search_id) {
              this.createTaskErrorSearchid = error.error.search_id[0];
            }
            if (error.error.assigned_to) {
              this.createTaskErrorAssigned_to = error.error.assigned_to[0];
            }
            if (error.error.end_date) {
              this.createTaskErrorend_date = error.error.end_date[0];
            }
            break;
          default:
          //Nothing
        }
        // this.notificationService.showError(error.message);
        console.log(error)
      }
    }
    )

  }

  resetBackendTaskerror() {
    this.createTaskErrorTitle = "";
    this.createTaskErrorDescription = "";
    this.createTaskErrorIsactive = "";
    this.createTaskErrorSearchid = "";
    this.createTaskErrorAssigned_to = "";
    this.createTaskErrorend_date = "";
    this.createTaskError = "";
    this.CreateTaskForm.reset();
  }


  saveTask(taskId: any) {
    if (taskId != undefined || taskId != null) {
      this.UpdateTasks();
    } else if (taskId == undefined || taskId == null) {
      this.createTask();
    }
  }





  open2(content: any, typeOfModal: any, EditId: number) {
    this.submitted = false;

    this.resetBackendTaskerror()
    if (typeOfModal == "newTask") {
      this.getUserList();
      let loginUserDetail: any = this.storageService.getValue(StorageKey.currentUser)
      let currentUser = JSON.parse(loginUserDetail);
      if (currentUser && currentUser.is_super_admin != 1)
        this.CreateTaskForm.controls['assignedTo'].setValue(currentUser.id)
      this.titleoftask = "Add"
      this.userProjectList = [];
      this.getUserProjectList();
      this.defaultTaskStatus = 1;
    } else if (typeOfModal == "editTask") {
      this.titleoftask = "Edit";
      this.EditTask(content, EditId);
    }
    this.taskId = EditId;

    if (typeOfModal == "AddTask") {
      //this.CreateTaskForm.reset();
      /*  let enddate = this.datePipe.transform(this.todayDate, 'dd/MM/yyyy ');
       this.CreateTaskForm.controls['endDate'].setValue(enddate); */
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed `;
    });
  }

  addNotes(data: any) {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = data.body.content;
    const trimmedTextContent = tempElement.textContent ? tempElement.textContent.trim() : '';
    this.CreateNoteForm.controls['notes'].setValue(trimmedTextContent);
  }


  // Get Email Details
  getEmailDetails(messageid: string, note_button_data: any) {
    this.emailMessageDetails = "";
    this.microsoftService.getMessageDetails({ id: messageid }).subscribe({
      next: (response: any) => {
        response.details.note_button_data = note_button_data;
        this.emailMessageDetails = response.details;
        this.addNotes(this.emailMessageDetails);
      },
      error: (error) => {
        switch (error.status) {
          case 401:
            this.notificationService.showError(error.message);
            break;
          default:
          //Nothing
        }
      }
    });

  }



  open(content: any, typeOfModal: any, data: any = {}) {
    this.resetBackendAddNoteerror();
    if (typeOfModal == 'createNote') {
      this.getEmailDetails(data.id, data.note_button_data);
      this.getCandidateNotes();
      this.getCandidateProjectList();
      this.CreateNoteForm.controls['notes_project'].setValue(0);
      // this.CreateNoteForm.controls['notes'].setValue(data.bodyPreview);
      this.CreateNoteForm.controls['ms_mail_id'].setValue(data.id);
      let created_date = new Date(data.createdDateTime);
      this.CreateNoteForm.controls['ms_mail_received_at'].setValue(created_date.getFullYear() + "-" + (((created_date.getMonth() + 1) < 10) ? "0" : "") + (created_date.getMonth() + 1) + "-" + ((created_date.getDate() < 10) ? "0" : "") + created_date.getDate() + " " + ((created_date.getHours() < 10) ? "0" : "") + created_date.getHours() + ":" + ((created_date.getMinutes() < 10) ? "0" : "") + created_date.getMinutes() + ":" + ((created_date.getSeconds() < 10) ? "0" : "") + created_date.getSeconds());
    }

    if (this.tenantlogin != '0') {
      this.setupCreateProjectForm();
    }
    if (typeOfModal == "EditProfile") {
      this.generateForm();
      this.EditProfile(content)
    }

    // if(typeOfModal=="newTask"){
    //    this.titleoftask = "Add"
    // } else if(typeOfModal=="editTask"){
    //     this.titleoftask = "Edit"
    // }

    // if(typeOfModal == "AddTask"){
    //   this.CreateTaskForm.reset();
    //    let enddate = this.datePipe.transform(this.todayDate, 'dd/MM/yyyy ');
    //   this.CreateTaskForm.controls['endDate'].setValue(enddate); 
    // }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed `;
    });
  }


  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  UpdateProfiles() {
    this.submitted = true;
    // if (this.f['name'].value.trim() == "") {
    //   this.notificationService.showError('Please enter name');
    //   return;
    // }
    let currentTenant = this.storageService.getValue(StorageKey.currentTenantId);
    this.tenantlogin = currentTenant;
    // if(currentTenant == '0'){
    //  return 
    // }

    let UpdatedUserDetails = {
      name: this.CreateUserForm.controls['name'].value,
      password: this.CreateUserForm.controls['password'].value,

    };
    this.projectapicall = true;
    const formData: FormData = new FormData();
    // console.log(this.url);
    if (this.fileName != null && (this.CreateUserForm.controls['password'].value != undefined || this.CreateUserForm.controls['password'].value != null)) {
      formData.append('name', this.fileName);
      formData.append('avatar', this.files);
      formData.append('name', this.CreateUserForm.controls['name'].value);
      formData.append('password', this.CreateUserForm.controls['password'].value);
    } else if (this.fileName == null && (this.CreateUserForm.controls['password'].value != undefined || this.CreateUserForm.controls['password'].value != null && this.url == null)) {
      //    alert(4);

      formData.append('is_avatar_removed', this.url3);
      formData.append('name', this.CreateUserForm.controls['name'].value);
      formData.append('password', this.CreateUserForm.controls['password'].value);
    } else if ((this.CreateUserForm.controls['password'].value != undefined || this.CreateUserForm.controls['password'].value != null) && this.fileName == null && this.url == null) {
      //  alert(1);
      // formData.append('avatar_path', this.url2);
      formData.append('is_avatar_removed', this.url3);
      formData.append('name', this.CreateUserForm.controls['name'].value);
    }
    else if (this.fileName != null && (this.CreateUserForm.controls['password'].value != undefined || this.CreateUserForm.controls['password'].value != null) && this.url == null) {
      // alert(2);
      formData.append('is_avatar_removed', this.url3);
      formData.append('name', this.fileName);
      formData.append('avatar', this.files);
      formData.append('name', this.CreateUserForm.controls['name'].value);
    }
    else if (this.CreateUserForm.controls['password'].value == undefined && this.fileName == null && this.url == null) {
      //  alert(3);
      formData.append('is_avatar_removed', this.url3);
      formData.append('name', this.CreateUserForm.controls['name'].value);

    } else if (this.CreateUserForm.controls['password'].value == undefined && this.fileName == null) {
      //  alert(5);
      formData.append('name', this.CreateUserForm.controls['name'].value);
    } else if (this.fileName != null && this.CreateUserForm.controls['password'].value == undefined) {
      //  alert(6)
      formData.append('name', this.fileName);
      formData.append('avatar', this.files);
      formData.append('name', this.CreateUserForm.controls['name'].value);
    }



    this.userservice.updateProfile(formData, currentTenant).subscribe({
      next: (response) => {
        this.modalService.dismissAll();
        this.CreateUserForm.reset();
        // this.dropimg = false;
        this.previewImg = true;
        this.projectapicall = false;
        this.submitted = false;
        this.getUserProfile();
        // window.location.reload();
      },
      error: (error) => {
        this.projectapicall = false;
        this.createUserError = error.error.message;
        switch (error.status) {
          case 422:
            if (error.error.avatar) {
              this.createUserErrorAvatar = error.error.avatar[0];
            }
            if (error.error.name) {
              this.createUserErrorName = error.error.name[0];
            }
            if (error.error.password) {
              this.createUserErrorPassword = error.error.password[0];
            }


            break;
          default:

          //Nothing

        }
        // this.notificationService.showError(error.message);
        console.log(error)

      }
    }
    )
  }


  attachFiles(event: any) {
    this.files = event.target.files[0];
    this.fileName = this.files.name;
    this.filePath = event.target.value;

    if (event.target.files) {
      var reader = new FileReader;
      reader.onload = (event: any) => {
        this.url = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
    // this.dropimg = false;
    this.previewImg = true;
    this.dropimg = false;

  }


  resetBackendClienterror() {
    this.createClientErrorName = "";
    this.createClientErrorIndustry = "";
    this.createClientErrorCity = "";
    this.createClientError = "";
    this.CreateClientForm.reset();
  }

  resetBackendCandidateerror() {
    this.createCandidateErrorUrl = "";
    this.createCandidateErrorProjectId = "";
    this.createCandidateError = "";
    this.CreateCandidateForm.reset();
  }

  resetBackendCandidateManuallyerror() {
    this.createCandidateManuallyError = "";
    this.CreateCandidateManuallyForm.reset();
    this.candidate_exists = [];
    // this.linkedin_candidate=true
  }

  resetBackendProjecterror() {
    this.createProjectErrorName = "";
    this.createProjectErrorDescription = "";
    this.createProjectErrorClientname = "";
    this.createProjectErrorOfficialstartdate = "";
    this.createProjectErrorType = "";
    this.createProjectErrorCity = "";
    this.createProjectError = "";
    this.CreateProjectForm.reset();
  }

  resetImageFile(element: any) {
    element.value = "";
    this.url = null;
  }

  onClientSearch(term: any) {
    let searchValue = term.term;

    if (true) {
      // searchValue.length>=2
      let data = { search: searchValue };
      this.clientService.searchClientwiseList(data).subscribe({
        next: (response: any) => {
          // console.log(response);
          this.clientsList = response.clients.data;
          this.clientsList = this.clientsList.filter((ele: any) => ele.is_active == 1);

        },
        error: (err: any) => {

        }
      })
    }
  }



  getClientsList() {
    this.clientService.listAll().subscribe({
      next: (response) => {
        this.clientsList = response.clients.data;
        this.clientsList = this.clientsList.filter((ele: any) => ele.is_active == 1);
      },
      error: (error) => {

      }
    });
  }

  loadMoreTask() {
    this.getTaskList(this.taskListActiveType, true)
  }

  getTaskList(type: string = 'due', load_more: boolean = false) {

    let currentTenant = this.storageService.getValue(StorageKey.currentTenantId);
    if (currentTenant == '0') {
      return
    }
    this.taskListActiveType = type;
    this.taskapicall = true;
    this.noTasksFound = "";
    if (!load_more)
      this.taskList = [];

    let data = { load_more: load_more, next_page_url: this.nextTaskListUrl, type: type, user_id: null }
    if (this.TaskForm.get('userId')!.value) {
      data.user_id = this.TaskForm.get('userId')!.value
    }



    this.taskService.listByFilter(data).subscribe({

      next: (response) => {
        if (response.tasks) {
          if (load_more)
            this.taskList = this.taskList.concat(response.tasks.data);
          else
            this.taskList = response.tasks.data

          if (response.tasks.data.length == 0)
            this.noTasksFound = "No Tasks Found";

          var taskLen = this.taskList.length
          var i;
          for (i = 0; i < taskLen; i++) {
            this.taskAssigneeName = this.taskList[i].assignee.name;
          }
          this.taskapicall = false;
          if (type == "due") {
            this.taskDueCount = response.tasks.total;
          }
          this.nextTaskListUrl = response.tasks.next_page_url;
          // console.log(this.noTasksFound);
        }
      },
      error: (error) => {
        this.createUserError = error.error.message;
        this.createUserError = error.error.url;
        console.log(error)
        // this.notificationService.showError(error.message);
      }
    });
  }

  getUserList(term: any = null) {

    let currentTenant = this.storageService.getValue(StorageKey.currentTenantId);
    if (currentTenant == '0') {
      return
    }
    let data = {};
    if (term && term.term) {
      data = { search: term.term };
    }
    this.userService.listByFilter(data).subscribe({
      next: (response) => {
        this.userList = response.users.data;
        let loginUserDetail: any = this.storageService.getValue(StorageKey.currentUser)
        let currentUser = JSON.parse(loginUserDetail)
        if (currentUser.is_super_admin != 1) {
          let currentUserid = this.storageService.getValue(StorageKey.currentUserid);
          let isUserIdInList: boolean = false;
          this.userList.forEach((e: any) => {
            if (e.id == currentUserid) {
              isUserIdInList = true;
            }
          })
          if (!isUserIdInList) {
            this.userList.push(currentUser);
          }
        }
      },
      error: (error) => {
        this.createUserError = error.error.message;
        this.createUserError = error.error.url;
        // this.notificationService.showError(error.message);
      }
    });
  }

  onUserSearch(term: any) {
    let searchValue = term.term;

    if (true)
    // searchValue.length>=2
    {
      let data = { search: searchValue };
      this.userService.searchUserwiseList(data).subscribe({
        next: (response: any) => {
          let currentUserid = this.storageService.getValue(StorageKey.currentUserid);
          this.userList = response.users.data;
          //this.userList = this.userList.filter((ele:any) => ele.is_active == 1);
          let isUserIdInList = this.userList.includes((e: any) => e.id == currentUserid);
          console.log(isUserIdInList);
          this.userList.forEach((ele: any) => {
            ele["dropdown"] = ele?.name + (ele.user?.name ? " [" + ele.user?.name + "]" : "")
          })
        },
        error: (err) => {

        }
      })
    }
  }

  convertDate(date: any) {
    const year = Number(date.split('/')[2]);
    const month = Number(date.split('/')[1]);
    const day = Number(date.split('/')[0]);
    let newdate = { year: year, month: month, day: day };
    console.log(newdate);
    return newdate;
  }


  deleteTask(deleteId: number) {
    this.taskService.deleteTaskData(deleteId)
      .subscribe({
        next: (response) => {
          //  this.tagList = response.tags.data;
          this.notificationService.showSuccess(response.message);
          this.getTaskList(this.taskListActiveType);
          // window.location.reload();
        },
        error: (error) => {
          this.notificationService.showError(error.message);
        },
      });
  }


  openDeleteModal(content: any, deleteId: number, value: any) {
    this.selectDeletedTask = value.title;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.deleteTask(deleteId);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  resetBackendAddNoteerror() {
    this.CreatNotesErrorProjectid = "";
    this.CreatNotesErrorNote = "";
    this.CreatNotesErrorAllowexport = "";
    this.CreatNotesError = "";
    this.CreateNoteForm.reset();
    this.projectapicall = false;
  }


  addNote() {
    this.submitted = true;

    if (this.CreateNoteForm.invalid) {
      return;
    }
    let currentUserid = this.candidate_id;
    let data = {
      id: currentUserid,
      project_id: this.CreateNoteForm.controls['notes_project'].value,
      note: this.CreateNoteForm.controls['notes'].value,
      allow_export: (this.CreateNoteForm.controls['allow_export'].value) ? 1 : 0,
      ms_mail_id: this.CreateNoteForm.controls['ms_mail_id'].value,
      ms_mail_received_at: this.CreateNoteForm.controls['ms_mail_received_at'].value
    }
    this.projectapicall = true;
    this.notesService.create(data).subscribe({
      next: (response) => {
        this.notificationService.showSuccess(response.message);
        this.modalService.dismissAll();

        //  this.CreateNoteForm.reset();
        this.submitted = false;
        this.projectapicall = false;
        // this.onLoad();
        // window.location.reload();
        this.getCandidateNotes();
        this.checkEmailAuth();
        this.resetBackendAddNoteerror();
        this.formDirective.resetForm();
      },
      error: (error) => {
        this.projectapicall = false;
        this.CreatNotesError = error.error.message;
        switch (error.status) {
          case 422:
            if (error.error.project_id) {
              this.CreatNotesErrorProjectid = error.error.project_id[0];
            }
            if (error.error.note) {
              this.CreatNotesErrorNote = error.error.note[0];
            }
            if (error.error.allow_export) {
              this.CreatNotesErrorAllowexport = error.error.allow_export[0];
            }
            break;
          default:
          //Nothing
        }
        // this.notificationService.showError(error.message);
      }
    })
  }

  getCandidateNotes() {
    let currentUserid = this.storageService.getValue(StorageKey.currentUserid);
    let data = { id: currentUserid, project_id: undefined, sort_field: "updated_at", sort_method: "desc" };
    if (this.CreateNoteForm.controls['notes_project'].value != null)
      data.project_id = this.CreateNoteForm.controls['notes_project'].value;

    this.notesService.listByFilter(data).subscribe({
      next: (response: any) => {
        this.notesList = response.notes.data;
        // console.log(this.notesList);
        this.notesList.forEach((element: any) => {
          element["initials"] = this.getInitials(element.creator.name);

        });
      },
      error: (error) => {
      }
    })

  }

  populateAddNotes(notes_project: any, notes: any, allow_export: any, ms_mail_id: string = "", ms_mail_received_at: string = "") {
    this.CreateNoteForm.controls['notes_project'].setValue(notes_project);
    this.CreateNoteForm.controls['notes'].setValue(notes);
    this.CreateNoteForm.controls['allow_export'].setValue((allow_export) ? 1 : 0);
    this.CreateNoteForm.controls['notes_project'].setValue(0);
    this.CreateNoteForm.controls['ms_mail_id'].setValue(ms_mail_id);
    this.CreateNoteForm.controls['ms_mail_received_at'].setValue(ms_mail_received_at);
  }

  checkEmailAuth(folder_name: any = 'inbox') {
    this.emailapicall = true;
    this.emailList = [];
    this.noEmailsFound = "";
    this.emailListActiveFolderName = folder_name;
    this.microsoftService.authorize({ page: window.location.href + '?authorized=true' }).subscribe({
      next: (response: any) => {
        if (response.url) {
          if (response.is_authorize) {
            this.microsoftService.getMessages({ candidate_id: this.candidate_id, folder_name: this.emailListActiveFolderName }).subscribe({
              next: (response: any) => {
                // console.log(response)
                this.emailapicall = false;
                this.emailList = response.list;
                this.MSEmail = response.user_email;
                // console.log(this.MSEmail)
                if (this.is_in_personal_email || this.in_work_email) {
                  if (this.emailList.length == 0)
                    this.noEmailsFound = "No emails found for this candidate";
                } else {
                  this.noEmailsFound = "No email is assigned for this candidate";
                }
              },
              error: (error: any) => {
                this.emailapicall = false;
              }
            });
            this.emailpopup.nativeElement.style.display = 'block';

          } else {
            this.emailAuthorizationRedirectURL = response.url;
            this.loginWithEmail.nativeElement.style.display = 'block';
            //window.location.href = response.url;
          }

        } else {
          //this.emailpopup.nativeElement.style.display='block';
        }
      },
      error: (error) => {
        switch (error.status) {
          case 401:
            this.notificationService.showError(error.message);
            break;
          default:
          //Nothing
        }
      }
    })


  }

  getcandidate_details(candidate_id: number) {
    if (candidate_id) {
      this.candidateService.candidateDetail(candidate_id).subscribe({
        next: (response: any) => {
          this.candidatedetails = response.candidate;
          this.is_in_personal_email = this.candidatedetails.in_personal_email
          this.in_work_email = this.candidatedetails.in_work_email
        },
        error: (error) => {
          // this.notificationService.showError(error.message);
        }
      });
    }

  }

  redirectToEmailAuthorization() {
    if (this.emailAuthorizationRedirectURL) {
      window.location.href = this.emailAuthorizationRedirectURL;
    }
  }

  showEmailDetails(messageid: string, note_button_data: any) {
    this.emailMessageDetails = "";
    this.emailDetails.nativeElement.style.display = 'block';
    this.microsoftService.getMessageDetails({ id: messageid }).subscribe({
      next: (response: any) => {
        response.details.note_button_data = note_button_data;
        this.emailMessageDetails = response.details;
        this.testing = this.emailMessageDetails.body.content
      },
      error: (error) => {
        switch (error.status) {
          case 401:
            this.notificationService.showError(error.message);
            break;
          default:
          //Nothing
        }
      }
    });

  }

  getEmailMessagePlainText(): string {
    if (this.emailMessageDetails && this.emailMessageDetails.body && this.emailMessageDetails.body.content) {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = this.emailMessageDetails.body.content;
      return tempElement.innerText.trim();
    }
    return "";
  }

  unauthorizeMs() {
    let data = {
      user_id: this.storageService.getValue(StorageKey.currentUserid)
    }
    this.microsoftService.unauthorizeMs(data).subscribe({
      next: (response: any) => {
        this.checkEmailAuth();
      },
      error: (error) => {
        switch (error.status) {
          case 401:
            this.notificationService.showError(error.message);
            break;
          default:
          //Nothing
        }
      }
    });

  }

  // getUserIdDetails() {
  //   const selectedUserId = this.TaskForm.get('userId')!.value;
  //   console.log('Selected User ID:', selectedUserId);
  // }


  getUserListtask(term: any = null) {

    let currentTenant = this.storageService.getValue(StorageKey.currentTenantId);
    if (currentTenant == '0') {
      return
    }
    let data = {};
    if (term && term.term) {
      data = { search: term.term };
    }
    this.userService.listByFilter(data).subscribe({
      next: (response) => {
        this.userListtask = response.users.data;
        let loginUserDetail: any = this.storageService.getValue(StorageKey.currentUser)
        let currentUser = JSON.parse(loginUserDetail)
        if (currentUser.is_super_admin != 1) {
          let currentUserid = this.storageService.getValue(StorageKey.currentUserid);
          let isUserIdInList: boolean = false;
          this.userListtask.forEach((e: any) => {
            if (e.id == currentUserid) {
              isUserIdInList = true;
            }
          })
          if (!isUserIdInList) {
            this.userListtask.push(currentUser);
          }
        }
      },
      error: (error) => {
        this.createUserError = error.error.message;
        this.createUserError = error.error.url;
        // this.notificationService.showError(error.message);
      }
    });
  }

  // var emailMessageDetails = {
  //   body: {
  //     contentType: "html",
  //     content: "<html><head><meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\"></head><body><div dir=\"ltr\">How many times did you happen to see an email with badly written copy, poorly rendered images, or broken links? It happens all the time, in fact, 80% of emails have at least one typo or a broken link or image in them. But such a blunder can cost a company a spoiled reputation no matter if you’re a small company or an enterprise. If it’s a problem that is consistent with all the emails a company sends you might even risk getting marked as spam by your recipients and getting on a blacklist.<br>How many times did you happen to see an email with badly written copy, poorly rendered images, or broken links? It happens all the time, in fact, 80% of emails have at least one typo or a broken link or image in them. But such a blunder can cost a company a spoiled reputation no matter if you’re a small company or an enterprise. If it’s a problem that is consistent with all the emails a company sends you might even risk getting marked as spam by your recipients and getting on a blacklist.<br></div></body></html>"
  //   }
  // };




}










