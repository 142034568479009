import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PagerService } from 'src/app/core/common/pager.service';
import { ClientService } from 'src/app/core/services/clients/client.service';
import { NotificationService } from 'src/app/core/services/common/notification.service';
import { AppSubHeaderComponent } from 'src/app/shared/components';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { StorageKey, StorageService } from 'src/app/core/common/storage.service';
import { CandidateService } from 'src/app/core/services/candidates/candidate.service';
import { UserService } from 'src/app/core/services/users/user.service';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css']
})
export class ClientListComponent implements OnInit {
  activePage: number = 1;
  clientList: any = [];
  candidateList: any = [];
  candidateData: any;
  clientStatusList: any = [];
  candidatePrimaryContact: string = "";
  total: number = 10;
  closeResult: string = "";
  createClientError: string = ""
  CreateClientForm!: FormGroup
  showCreateButton: string = "client";
  EditId!: number;
  pageNumber: number = 1;
  pageSize: number = 3;
  totalRows: number = 0;
  pager: any = {};
  pageTitle: string = "Clients";
  isSearchable: boolean = true;
  stateList: any;
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // phoneNumber = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  //ph = /[0-9\+\-\ ]/;
  ph2 = /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})/;
  ph3 = /[- +()0-9]+/;
  submitted: boolean = false;
  selectDeletedClient: string | undefined;
  // for sorting list
  faArrowUp = faCaretUp;
  faArrowDown = faCaretDown;
  sortfield: any;
  sortmethod: any;
  iconVisible: boolean = true;
  iconVisibleAsc: boolean = false;
  iconVisibleDesc: boolean = false;
  staticarrowsVisible: boolean = false;
  currentSortedColumn: any;
  currentSortedMethod: any;
  sortedColumn: any;
  createClientErrorName: any;
  createClientErrorIndustry: any;
  createClientErrorContactnumber: any;
  createClientErrorEmail: any;
  createClientErrorAddress: any;
  createClientErrorPostal: any;
  createClientErrorCity: any;
  createClientErrorState: any;
  createClientErrorWebsite: any;
  createClientErrorDescription: any;
  projectapicall: boolean = false;
  currentSearchValue: string = "";
  getSearchList: any;
  createClientErrorPrimarycontact: any;
  fileName!: string;
  filePath!: string;
  files: any;
  showUploadFileButton: boolean = false
  openDocumentSection: boolean = false;
  openDocumentButtton: boolean = true;
  documentData: any;
  ClientId!: number;
  createClientErrorWeblinkone: any;
  createClientErrorWeblinktwo: any;
  createClientErrorWeblinkthree: any;
  deleteDocumentId: any
  uploadsubmitted: boolean = false;
  CreatDocumentsErrorDocuments: any;
  url: any;
  openLogoSection: boolean = false;
  openLogoButtton: boolean = true;
  files2: any;
  fileName2: any;
  filePath2: any;
  rectangleBox: boolean = true;
  logoBox: boolean = false;
  projectapicall2: boolean = false;
  createClientErrorLogo: any;
  CreatDocumentsErrorDocumentsname: any;
  selectFile: any;
  showexternallink1: boolean = false;
  showexternallink2: boolean = false;
  showexternallink3: boolean = false;
  editexternallink1: boolean = false;
  editexternallink2: boolean = false;
  editexternallink3: boolean = false;

  weblink1: string = "";
  weblink2: string = "";
  weblink3: string = "";
  weblink!: string;
  mouseOnColumnName: string = "";
  static_primary_contact: boolean = true;
  ClientProbabiltylist: any = [];
  userList: any;

  constructor(private clientService: ClientService,
    private candidateService: CandidateService,
    private notificationService: NotificationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private pagerService: PagerService,
    private storageService: StorageService,
    private userService: UserService) {

    let is_client_module_access_disabled = this.storageService.getValue('is_client_module_access_disabled')

    if (is_client_module_access_disabled == '1')
      this.router.navigate(['search-list']);
  }

  ngOnInit() {
    let listSettings: any = this.storageService.getValue(StorageKey.listSettings);
    if (listSettings !== "undefined" && listSettings)
      this.getSearchList = JSON.parse(listSettings);
    if (this.getSearchList && this.getSearchList.clients) {
      this.getClientList(null, this.getSearchList.clients);
    } else
      this.getClientList({});

    this.getclientprobability()
    this.getUsersList();

  }

  get f() { return this.CreateClientForm.controls; }

  generateForm() {
    this.CreateClientForm = this.formBuilder.group({
      ClientId: [null],
      name: [null, [Validators.required]],
      user_id: [null, [Validators.required]],
      industry: [null],
      city: [null],
      state: [null],
      contact_email: [null, [Validators.pattern(this.emailPattern)]],
      //contact_number:[null,[Validators.pattern("[0-9 ]{12}")]],
      contact_number: [null, [Validators.pattern(this.ph3)]],
      postal_code: [null],
      street_address: [null],
      description: [null],
      website: [null],
      primary_contact: [null],
      primary_contact_static: [null],
      client_status_id: [0],
      client_probability_status_id: [0],
      no_of_searches: [null],
      web_link_one: [null, [Validators.pattern(this.getURLPattern())]],
      web_link_two: [null, [Validators.pattern(this.getURLPattern())]],
      web_link_three: [null, [Validators.pattern(this.getURLPattern())]],
      // web_link_one:[null,[this.validateUrl]],
      document: [null],
      logo: [null],
      candidate_id: [null],
      is_referenceable: [null]


    })
  }

  private getURLPattern(): string {
    return '^(http|https)://([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?$';
  }

  getClientList(data1: any, data = {
    page: this.pageNumber,
    sort_field: this.currentSortedColumn,
    sort_method: this.currentSortedMethod,
    column_name: this.sortedColumn,
    search: this.currentSearchValue
  }, reset: boolean = false) {

    if (data1) {
      data.sort_field = data1.sort_field;
      data.sort_method = data1.sort_method;
      data.column_name = data1.column_name;
    }

    if (!reset) {
      if (this.getSearchList) {
        this.currentSearchValue = data.search;
        this.getSearchList.clients = data;
        this.storageService.setValue('listSettings', JSON.stringify(this.getSearchList));
      } else
        this.storageService.setValue('listSettings', JSON.stringify({ clients: data }));
    } else {
      data.search = "";
      data.page = 1;
    }

    this.clientService.listByFilter(data).subscribe({
      next: (response) => {
        this.clientList = response.clients.data;
        this.pageNumber = response.clients.current_page;
        this.totalRows = response.clients.total;
        this.pager = this.pagerService.getPager(this.totalRows, this.pageNumber);
        if (data.sort_field) {
          this.iconVisible = false;
          this.staticarrowsVisible = true;
          this.currentSortedColumn = data.column_name;
          this.sortedColumn = data.column_name;
          if (data.sort_method === 'desc') {
            this.iconVisibleDesc = true;
            this.iconVisibleAsc = false;
            this.currentSortedMethod = 'desc';
          }
          if (data.sort_method === 'asc') {
            this.iconVisibleAsc = true;
            this.iconVisibleDesc = false;
            this.currentSortedMethod = 'asc';
          }
          this.sortfield = response.clients.sort_field;
          this.sortmethod = response.clients.sort_method;
        }
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      }
    })
    this.getCandidatePhoneNumber();
    this.getClientStatuses();
  }

  mouseEnter(columnname: any) {
    this.iconVisible = true;
    this.staticarrowsVisible = false;
    //this.currentSortedColumn = columnname;
    this.mouseOnColumnName = columnname;
    if (columnname == 'name' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'client_status_id' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'primary_contact' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'contact_number' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'contact_email' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'city' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }

    if (columnname == 'client_probability_status_id' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }

  }

  mouseLeave(columnname: any) {
    this.iconVisible = false;
    this.staticarrowsVisible = true;
  }

  getclientprobability() {
    this.clientService.getclientprobability().subscribe({
      next: (response: any) => {
        this.ClientProbabiltylist = response.client_probability_statuses.data;
      },
      error: (error) => {
      }
    })
  }

  EditClient(content: any, EditId: any) {
    if (EditId != undefined || EditId != null) {
      this.clientService.getClientData({ id: EditId }).subscribe((data) => {
        let response = data.client
        this.ClientId = data.client.id;
        this.getDocumentData();
        this.getUsersList({ term: response.owner?.name});
        this.url = data.client.logo_path;
        this.rectangleBox = true;
        this.openLogoSection = false;
        if (this.url == null) {
          this.rectangleBox = true;
          this.logoBox = false;
        } else if (this.url != null) {
          this.rectangleBox = false;
          this.logoBox = true;
        }

        if (response.web_link_one !== 'null' && response.web_link_one != null) {
          this.CreateClientForm.controls['web_link_one'].setValue(response.web_link_one);
          this.showexternallink1 = true;
          this.weblink1 = response.web_link_one;
          this.editexternallink1 = false;
        } else {
          this.CreateClientForm.controls['web_link_one'].setValue("");
          this.showexternallink1 = false;
          this.weblink1 = "";
          this.editexternallink1 = true;
        }

        if (response.web_link_two !== 'null' && response.web_link_two != null) {
          this.CreateClientForm.controls['web_link_two'].setValue(response.web_link_two);
          this.showexternallink2 = true;
          this.weblink2 = response.web_link_two;
          this.editexternallink2 = false;
        } else {
          this.CreateClientForm.controls['web_link_two'].setValue("");
          this.showexternallink2 = false;
          this.weblink2 = "";
          this.editexternallink2 = true;
        }

        if (response.web_link_three !== 'null' && response.web_link_three != null) {
          this.CreateClientForm.controls['web_link_three'].setValue(response.web_link_three);
          this.showexternallink3 = true;
          this.weblink3 = response.web_link_three;
          this.editexternallink3 = false;
        } else {
          this.CreateClientForm.controls['web_link_three'].setValue("");
          this.showexternallink3 = false;
          this.weblink3 = "";
          this.editexternallink3 = true;
        }

        if (response.description !== 'null' && response.description != null) {
          this.CreateClientForm.controls['description'].setValue(response.description);
        } else {
          this.CreateClientForm.controls['description'].setValue("");

        }


        // 

        if (response.primary_contact !== 'null' && response.primary_contact != null) {
          this.CreateClientForm.controls['primary_contact_static'].setValue(response.primary_contact);
          this.CreateClientForm.controls['primary_contact'].setValue(response.primary_contact);
          this.f['primary_contact_static'].disable();
        } else {

        }

        if (response.projects_count !== 'null' && response.projects_count != null) {
          this.CreateClientForm.controls['no_of_searches'].setValue(response.projects_count);
        } else {
          this.CreateClientForm.controls['no_of_searches'].setValue("");
        }
        this.f['no_of_searches'].disable();

        if (response.no !== 'null' && response.client_status_id != 0) {
          this.CreateClientForm.controls['client_status_id'].setValue(response.client_status_id);
        } else {
          this.CreateClientForm.controls['client_status_id'].setValue(0);
        }

        // Proability staus Id
        if (response.no !== 'null' && response.client_probability_status_id != 0) {
          this.CreateClientForm.controls['client_probability_status_id'].setValue(response.client_probability_status_id);
        } else {
          this.CreateClientForm.controls['client_probability_status_id'].setValue(0);
        }



        if (response.industry !== 'null' && response.industry != null) {
          this.CreateClientForm.controls['industry'].setValue(response.industry);
        } else {
          this.CreateClientForm.controls['industry'].setValue("");

        }

        if (response.street_address !== 'null' && response.street_address != null) {
          this.CreateClientForm.controls['street_address'].setValue(response.street_address);
        } else {
          this.CreateClientForm.controls['street_address'].setValue("");

        }

        if (response.postal_code !== 'null' && response.postal_code != null) {
          this.CreateClientForm.controls['postal_code'].setValue(response.postal_code);
        } else {
          this.CreateClientForm.controls['postal_code'].setValue("");

        }

        if (response.city !== 'null' && response.city != null) {
          this.CreateClientForm.controls['city'].setValue(response.city);
        } else {
          this.CreateClientForm.controls['city'].setValue("");

        }

        if (response.state !== 'null' && response.state != null) {
          this.CreateClientForm.controls['state'].setValue(response.state);
        } else {
          this.CreateClientForm.controls['state'].setValue(null);
        }


        if (response.contact_email !== 'null' && response.contact_email != null) {
          this.CreateClientForm.controls['contact_email'].setValue(response.contact_email);
        } else {
          this.CreateClientForm.controls['contact_email'].setValue("");
        }


        if (response.contact_number !== 'null' && response.contact_number != null) {
          this.CreateClientForm.controls['contact_number'].setValue(response.contact_number);
        } else {
          this.CreateClientForm.controls['contact_number'].setValue("");
        }

        this.CreateClientForm.controls['ClientId'].setValue(response.id);
        this.CreateClientForm.controls['name'].setValue(response.name);

        if (response.candidate_id == null) {
          this.CreateClientForm.controls['candidate_id'].setValue('');
        } else {
          this.CreateClientForm.controls['candidate_id'].setValue(response.candidate_id);
        }

        if (response.owner == null) {
          this.CreateClientForm.controls['user_id'].setValue(null);
        } else {
          this.CreateClientForm.controls['user_id'].setValue(response.owner.id);
        }

        if (response.is_referenceable == null) {
          this.CreateClientForm.controls['is_referenceable'].setValue(null);
        } else {
          this.CreateClientForm.controls['is_referenceable'].setValue(response.is_referenceable);
        }

        //  this.CreateClientForm.controls['industry'].setValue(response.industry);
        //  this.CreateClientForm.controls['city'].setValue(response.city);
        // this.CreateClientForm.controls['state'].setValue(response.state);
        //  this.CreateClientForm.controls['contact_email'].setValue(response.contact_email);
        // this.CreateClientForm.controls['contact_number'].setValue(response.contact_number);
        //this.CreateClientForm.controls['postal_code'].setValue(response.postal_code);
        //this.CreateClientForm.controls['street_address'].setValue(response.street_address);
        //  this.CreateClientForm.controls['description'].setValue(response.description);
        //  this.CreateClientForm.controls['website'].setValue(response.website);
        // this.CreateClientForm.controls['primary_contact'].setValue(response.primary_contact);
        // this.CreateClientForm.controls['web_link_one'].setValue(response.web_link_one);
        // this.CreateClientForm.controls['web_link_two'].setValue(response.web_link_two);
        // this.CreateClientForm.controls['web_link_three'].setValue(response.web_link_three);


        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed `;
        });
      })
    }
  }

  open(content: any, EditId: number) {

    this.generateForm();
    this.getStateList();
    this.EditClient(content, EditId);


  }

  resetBackendClienterror() {
    this.createClientErrorName = "";
    this.createClientErrorIndustry = "";
    this.createClientErrorContactnumber = "";
    this.createClientErrorEmail = "";
    this.createClientErrorAddress = "";
    this.createClientErrorPostal = "";
    this.createClientErrorCity = "";
    this.createClientErrorState = "";
    this.createClientErrorWebsite = "";
    this.createClientErrorPrimarycontact = "";
    this.createClientErrorCity = "";
    this.createClientErrorPrimarycontact = "";
    this.createClientErrorWeblinkone = "";
    this.createClientErrorWeblinktwo = "";
    this.createClientErrorWeblinkthree = "";
    this.createClientErrorLogo = "";
    this.CreatDocumentsErrorDocuments = "";
    this.createClientError = "";
    this.CreatDocumentsErrorDocumentsname = "";
    this.CreateClientForm.reset();
  }

  onUserSearch(term: any) {
    let searchValue = term.term;
    if (true) {
      let data = { search: searchValue };
      this.userService.searchUserwiseList(data).subscribe({
        next: (response: any) => {
          let currentUserid = this.storageService.getValue(StorageKey.currentUserid);
          this.userList = response.users.data;
          let isUserIdInList = this.userList.includes((e: any) => e.id == currentUserid);
          this.userList.forEach((ele: any) => {
            ele["dropdown"] = ele?.name + (ele.user?.name ? " [" + ele.user?.name + "]" : "")
          })
        },
        error: (err) => {
        }
      })
    }
  }


  // getUsersList() {
  //   this.userService.listAll().subscribe({
  //     next: (response) => {
  //       this.ownerList = response.users.data;
  //     },
  //     error: () => { }
  //   });
  // }

  // getUserList() {
  //   let currentTenant = this.storageService.getValue(StorageKey.currentTenantId);
  //   if (currentTenant == '0') {
  //     return
  //   }
  //   this.userService.listAll().subscribe({
  //     next: (response) => {
  //       this.userList = response.users.data;
  //     },
  //     error: (error) => {
  //       ;
  //       // this.notificationService.showError(error.message);
  //     }
  //   });
  // }

  // getUsersList() {
  //   this.userService.listAll().subscribe({
  //     next: (response) => {
  //       this.userList = response.users.data;
  //     },
  //     error: () => { }
  //   });
  // }

  getUsersList(term: any = null) {

    let currentTenant = this.storageService.getValue(StorageKey.currentTenantId);
    if (currentTenant == '0') {
      return
    }
    let data = {};
    if (term && term.term) {
      data = { search: term.term };
    }
    this.userService.listByFilter(data).subscribe({
      next: (response) => {
        this.userList = response.users.data;
        let loginUserDetail: any = this.storageService.getValue(StorageKey.currentUser)
        let currentUser = JSON.parse(loginUserDetail)
        if (currentUser.is_super_admin != 1) {
          let currentUserid = this.storageService.getValue(StorageKey.currentUserid);
          let isUserIdInList: boolean = false;
          this.userList.forEach((e: any) => {
            if (e.id == currentUserid) {
              isUserIdInList = true;
            }
          })
          if (!isUserIdInList) {
            this.userList.push(currentUser);
          }
        }
      },
      error: (error) => {
      }
    });
  }


  UpdateClients() {
    this.submitted = true;
    if (this.CreateClientForm.invalid) {
      return;
    }
   
    let UpdatedClientDetails = {
      id: this.CreateClientForm.controls['ClientId'].value,
      name: this.CreateClientForm.controls['name'].value,
      user_id: this.CreateClientForm.controls['user_id'].value,
      industry: this.CreateClientForm.controls['industry'].value,
      city: this.CreateClientForm.controls['city'].value,
      state: this.CreateClientForm.controls['state'].value,
      contact_email: this.CreateClientForm.controls['contact_email'].value,
      contact_number: this.CreateClientForm.controls['contact_number'].value,
      postal_code: this.CreateClientForm.controls['postal_code'].value,
      street_address: this.CreateClientForm.controls['street_address'].value,
      description: this.CreateClientForm.controls['description'].value,
      website: this.CreateClientForm.controls['website'].value,
      primary_contact: (this.CreateClientForm.controls['primary_contact'].value) ? this.CreateClientForm.controls['primary_contact'].value : this.CreateClientForm.controls['primary_contact_static'].value,
      client_status_id: (this.CreateClientForm.controls['client_status_id'].value == null) ? 0 : this.CreateClientForm.controls['client_status_id'].value,
      web_link_one: this.CreateClientForm.controls['web_link_one'].value,
      web_link_two: this.CreateClientForm.controls['web_link_two'].value,
      web_link_three: this.CreateClientForm.controls['web_link_three'].value,
      candidate_id: this.CreateClientForm.controls['candidate_id'].value,
      client_probability_status_id: (this.CreateClientForm.controls['client_probability_status_id'].value == null) ? 0 : this.CreateClientForm.controls['client_probability_status_id'].value,
      is_referenceable: this.CreateClientForm.controls['is_referenceable'].value ? 1 : 0,

    };

    this.projectapicall = true;
    const formData: FormData = new FormData();

    if (this.fileName2 != null) {
      formData.append('logoname', this.fileName2);
      formData.append('logo', this.files2);
      formData.append('ClientId', this.CreateClientForm.controls['ClientId'].value);
      formData.append('name', this.CreateClientForm.controls['name'].value);
      formData.append('user_id', this.CreateClientForm.controls['user_id'].value);
      formData.append('industry', this.CreateClientForm.controls['industry'].value);
      formData.append('city', this.CreateClientForm.controls['city'].value);
      formData.append('state', this.CreateClientForm.controls['state'].value);
      formData.append('contact_email', this.CreateClientForm.controls['contact_email'].value);
      formData.append('contact_number', this.CreateClientForm.controls['contact_number'].value);
      formData.append('postal_code', this.CreateClientForm.controls['postal_code'].value);
      formData.append('street_address', this.CreateClientForm.controls['street_address'].value);
      formData.append('description', this.CreateClientForm.controls['description'].value);
      formData.append('website', this.CreateClientForm.controls['website'].value);
      formData.append('primary_contact', (this.CreateClientForm.controls['primary_contact'].value) ? this.CreateClientForm.controls['primary_contact'].value : this.CreateClientForm.controls['primary_contact_static'].value);
      formData.append('client_status_id', (this.CreateClientForm.controls['client_status_id'].value == null) ? 0 : this.CreateClientForm.controls['client_status_id'].value);
      formData.append('web_link_one', this.CreateClientForm.controls['web_link_one'].value);
      formData.append('web_link_two', this.CreateClientForm.controls['web_link_two'].value);
      formData.append('web_link_three', this.CreateClientForm.controls['web_link_three'].value);
      formData.append('candidate_id', this.CreateClientForm.controls['candidate_id'].value);
      formData.append('client_probability_status_id', (this.CreateClientForm.controls['client_probability_status_id'].value == null) ? 0 : this.CreateClientForm.controls['client_probability_status_id'].value);
      formData.append('is_referenceable', this.CreateClientForm.controls['is_referenceable'].value ? '1' : '0');
    } else if (this.fileName2 == null) {
      formData.append('ClientId', this.CreateClientForm.controls['ClientId'].value);
      formData.append('name', this.CreateClientForm.controls['name'].value);
      formData.append('user_id', this.CreateClientForm.controls['user_id'].value);
      formData.append('industry', this.CreateClientForm.controls['industry'].value);
      formData.append('city', this.CreateClientForm.controls['city'].value);
      formData.append('state', this.CreateClientForm.controls['state'].value);
      formData.append('contact_email', this.CreateClientForm.controls['contact_email'].value);
      formData.append('contact_number', this.CreateClientForm.controls['contact_number'].value);
      formData.append('postal_code', this.CreateClientForm.controls['postal_code'].value);
      formData.append('street_address', this.CreateClientForm.controls['street_address'].value);
      formData.append('description', this.CreateClientForm.controls['description'].value);
      formData.append('website', this.CreateClientForm.controls['website'].value);
      formData.append('primary_contact', (this.CreateClientForm.controls['primary_contact'].value) ? this.CreateClientForm.controls['primary_contact'].value : this.CreateClientForm.controls['primary_contact_static'].value);
      formData.append('client_status_id', (this.CreateClientForm.controls['client_status_id'].value == null) ? 0 : this.CreateClientForm.controls['client_status_id'].value);
      formData.append('web_link_one', this.CreateClientForm.controls['web_link_one'].value);
      formData.append('web_link_two', this.CreateClientForm.controls['web_link_two'].value);
      formData.append('web_link_three', this.CreateClientForm.controls['web_link_three'].value);
      formData.append('candidate_id', this.CreateClientForm.controls['candidate_id'].value);
      formData.append('client_probability_status_id', (this.CreateClientForm.controls['client_probability_status_id'].value == null) ? 0 : this.CreateClientForm.controls['client_probability_status_id'].value);
      formData.append('is_referenceable', this.CreateClientForm.controls['is_referenceable'].value ? '1' : '0');
    }

    this.clientService.updateClientData(UpdatedClientDetails.id, formData).subscribe({
      next: (response) => {
        this.modalService.dismissAll();
        this.CreateClientForm.reset();
        this.notificationService.showSuccess(response.message);
        //  window.location.reload();
        this.projectapicall = false;
        this.submitted = false;
        //  this.getClientList({});
        for (let i = 0; i < this.clientList.length; i++) {
          if (this.clientList[i].id === UpdatedClientDetails.id) {
            this.clientList[i] = response.client;
            break;
          }
        }

      },
      error: (error) => {
        this.projectapicall = false;
        this.createClientError = error.error.message;
        // this.createClientError = error.error.url;
        switch (error.status) {
          case 422:
            if (error.error.name) {
              this.createClientErrorName = error.error.name[0];
            }
            if (error.error.industry) {
              this.createClientErrorIndustry = error.error.industry[0];
            }
            if (error.error.contact_number) {
              this.createClientErrorContactnumber = error.error.contact_number[0];
            }
            if (error.error.contact_email) {
              this.createClientErrorEmail = error.error.contact_email[0];
            }
            if (error.error.street_address) {
              this.createClientErrorAddress = error.error.street_address[0];
            }
            if (error.error.postal_code) {
              this.createClientErrorPostal = error.error.postal_code[0];
            }
            if (error.error.city) {
              this.createClientErrorCity = error.error.city[0];
            }
            if (error.error.state) {
              this.createClientErrorState = error.error.state[0];
            }
            if (error.error.website) {
              this.createClientErrorWebsite = error.error.website[0];
            }
            if (error.error.description) {
              this.createClientErrorDescription = error.error.description[0];
            }
            if (error.error.primary_contact) {
              this.createClientErrorPrimarycontact = error.error.primary_contact[0];
            }
            if (error.error.web_link_one) {
              this.createClientErrorWeblinkone = error.error.web_link_one[0];
            }
            if (error.error.web_link_two) {
              this.createClientErrorWeblinktwo = error.error.web_link_two[0];
            }
            if (error.error.web_link_three) {
              this.createClientErrorWeblinkthree = error.error.web_link_three[0];
            }
            if (error.error.logo) {
              this.createClientErrorLogo = error.error.logo[0];
            }


            break;

          default:

          //Nothing

        }
        // this.createClientErrorName = error.error.name;
        // this.notificationService.showError(error.message);
      }
    }
    )

  }

  getCandidatePhoneNumber(selected_value = "") {
    /* let multiplesorting:any = []
    multiplesorting['in_personal_phone'] =  ['in_personal_phone', 'desc']; */
    this.candidateService.listByFilter({ search: selected_value }).subscribe({
      next: (response) => {
        this.candidateList = response.candidates.data;
        this.candidateList.forEach((element: any) => {
          // element.full_name = element.in_first_name + " " + element.in_last_name
          element.full_name = element.in_first_name + " " + element.in_last_name + " " + (element.current_company ? '[' + element.current_company + ']' : '');

        });
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      }
    });
  }

  getClientStatuses() {
    this.clientService.clientStatusListByFilter({}).subscribe({
      next: (response) => {
        this.clientStatusList = response.client_statuses.data;
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      }
    });
  }

  public searchData(value: any) {
    this.currentSearchValue = value;
    this.pageNumber = 1;
    this.getClientList({ page: this.pageNumber, sort_field: this.currentSortedColumn, sort_method: this.currentSortedMethod, column_name: this.sortedColumn, search: value });
  }

  setPage(page: number) {
    this.pageNumber = page;
    this.getClientList({ page: this.pageNumber, sort_field: this.currentSortedColumn, sort_method: this.currentSortedMethod, column_name: this.sortedColumn });
  }


  openDeleteModal(content: any, deleteId: number, value: any) {
    this.selectDeletedClient = value.name;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        //  this.notificationService.showWarning("All the Searches of this client will be also deleted");
        this.deleteClient(deleteId);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  deleteClient(deleteId: number) {
    this.clientService.deleteClientData(deleteId)
      .subscribe({
        next: (response) => {
          //  this.tagList = response.tags.data;
          this.notificationService.showSuccess(response.message);
          this.getClientList({});

        },
        error: (error) => {
          this.notificationService.showError(error.message);
        },
      });
  }

  getStateList() {
    this.clientService.listStateAll().subscribe({
      next: (response) => {
        this.stateList = response.states;


      },
      error: (error: any) => {
        this.notificationService.showError(error.message);
      }
    });
  }

  resetListSettings() {
    let listSettings: any = this.storageService.getValue(StorageKey.listSettings);
    if (listSettings !== "undefined" && listSettings)
      this.getSearchList = JSON.parse(listSettings);
    if (this.getSearchList && this.getSearchList.clients) {
      delete this.getSearchList.clients;
      this.storageService.setValue('listSettings', JSON.stringify(this.getSearchList));
    }
    this.iconVisible = true;
    this.currentSearchValue = "";
    this.currentSortedColumn = "";
    this.getClientList({}, undefined, true);
  }

  attachFiles(event: any) {
    this.files = event.target.files[0];
    this.fileName = this.files.name;
    this.filePath = event.target.value;
    this.showUploadFileButton = true;
  }


  // openDocument(){
  //   this.openDocumentSection =true;
  //   this.openDocumentButtton = false;
  // }

  UploadDocuments() {
    this.showUploadFileButton = false;
    this.uploadsubmitted = true;
    if (!this.files) {
      return;
    }


    const formData: FormData = new FormData();
    formData.append('name', this.fileName);
    formData.append('document', this.files);

    this.projectapicall2 = true;
    this.clientService.createDocument(this.ClientId, formData).subscribe({
      next: (response: any) => {
        // this.onLoad();
        // this.modalService.dismissAll();
        this.notificationService.showSuccess(response.message);
        this.projectapicall2 = false;
        this.uploadsubmitted = false;
        this.getDocumentData();
        this.CreateClientForm.controls['document'].setValue(null);


      },
      error: (error) => {
        this.projectapicall2 = false;
        // this.CreatDocumentsError = error.error.message;
        switch (error.status) {
          case 422:
            if (error.error.document) {
              this.CreatDocumentsErrorDocuments = error.error.document[0];
            }

            if (error.error.name) {
              this.CreatDocumentsErrorDocumentsname = error.error.name[0];
            }
            break;
          default:
          //Nothing
        }
        // this.notificationService.showError(error.message);
      }
    })
  }

  deleteDocuments(deleteId: any) {
    this.clientService.deleteDocument(this.ClientId, deleteId).subscribe({
      next: (response: any) => {
        this.notificationService.showSuccess(response.message);
        this.getDocumentData();

      },
      error: (err: any) => {
      }
    })
  }

  getDocumentData() {
    this.clientService.getDocumentData(this.ClientId).subscribe({
      next: (response) => {
        this.documentData = response.client.documents;
      },
      error: (error: any) => {
        this.notificationService.showError(error.message);
      }
    });
  }

  // for image
  openLogobox() {
    this.openLogoSection = true;
    this.openLogoButtton = false;
  }

  attachLogoFiles(event: any) {

    this.files2 = event.target.files[0];
    this.fileName2 = this.files2.name;
    this.filePath2 = event.target.value;

    if (event.target.files) {
      this.logoBox = true;
      this.rectangleBox = false;
      var reader = new FileReader;
      reader.onload = (event: any) => {
        this.url = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }


  populateEmail(event: any) {
    if (event && event.id != undefined) {
      this.f['primary_contact_static'].disable();
      this.static_primary_contact = false;
      this.candidateService.candidateDetail(event.id).subscribe({
        next: (response: any) => {
          this.candidateData = response.candidate;
          this.f['contact_number'].setValue("");
          this.f['contact_email'].setValue("");
          this.f['primary_contact_static'].setValue("");
          this.f['candidate_id'].setValue("");

          if (this.candidateData.is_in_personal_email_preffered) {
            this.f['contact_email'].setValue(event.in_personal_email);
          } else if (this.candidateData.is_in_work_email_preffered) {
            this.f['contact_email'].setValue(event.in_work_email);
          } else if (this.candidateData.in_personal_email) {
            this.f['contact_email'].setValue(event.in_personal_email);
          } else if (this.candidateData.in_work_email) {
            this.f['contact_email'].setValue(event.in_work_email);
          }

          if (this.candidateData.is_in_personal_phone_preffered) {
            this.f['contact_number'].setValue(event.in_personal_phone);
          } else if (this.candidateData.is_in_work_phone_preffered) {
            this.f['contact_number'].setValue(event.in_work_phone);
          } else if (this.candidateData.in_personal_phone) {
            this.f['contact_number'].setValue(event.in_personal_phone);
          } else if (this.candidateData.in_work_phone) {
            this.f['contact_number'].setValue(event.in_work_phone);
          }

          this.f['primary_contact_static'].setValue(event.full_name);
          this.f['candidate_id'].setValue(event.id);

          this.getCandidatePhoneNumber();
        },
        error: () => {

        }
      });

    } else {
      this.f['primary_contact_static'].enable();
      /*  if((event.type != "keyup") && (event.type == "blur" && this.f['primary_contact'].value != "")){
         this.static_primary_contact=true;
         this.f['primary_contact'].setValue("");
       }
       this.candidateData="";
       if(event.type != "blur")
       this.f['primary_contact_static'].setValue(event.target.value); */
      if (event && event.target)
        this.getCandidatePhoneNumber(event.target.value);
      this.f['contact_number'].setValue("");
      this.f['contact_email'].setValue("");
      this.f['primary_contact_static'].setValue("");
      this.f['candidate_id'].setValue("");
    }
  }

  showCandidate(id: number | null) {
    if (id !== null) {
      this.router.navigate(['/candidate/' + id]);
    } else {
      console.error('Invalid candidate id');
    }
  }



}
