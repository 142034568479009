<div class="top-left-sec w-50">
  <h3 class="d-inline-block align-middle m-0">{{pageTitle}}</h3>
  <div class="d-inline-block align-middle m-0 ps-3 pt-1" style="cursor:pointer;" title="reset"
    (click)="onRefreshClick()"><em class="fa fa-refresh"></em></div>
  <div class="create d-inline-block">

    <!-- Button trigger modal -->
    <button type="button" class="border-0 bg-transparent" *ngIf="showCreateButton == 'candidate'"
      (click)="open(CreateCandidate)">
      <a class="blue-btn ms-4">+ Add</a>
    </button>

    <button type="button" class="border-0 bg-transparent" *ngIf="showCreateButton == 'candidate'"
      (click)="open(CandidateBulkImport)">
      <a class="blue-btn ms-4">Import</a>
    </button>

    <!-- Show button if is_public_search is '0' -->
    <button type="button" class="border-0 bg-transparent"
      *ngIf="pageTitle === 'Searches' && showCreateButton === 'project'" (click)="open(CreateProject)">
      <a class="blue-btn ms-4">+ Add</a>
    </button>

    <button type="button" class="border-0 bg-transparent"
      *ngIf="pageTitle === 'Job Posting' && showCreateButton === 'project'" (click)="openaddjob(AddJobPosting)">
      <a class="blue-btn ms-4">+ Add</a>
    </button>



   






    <button type="button" class="border-0 bg-transparent" *ngIf="showCreateButton == 'client'"
      (click)="open(CreateClient)">
      <a class="blue-btn ms-4">+ Add</a>
    </button>

    <button type="button" class="border-0 bg-transparent" *ngIf="showCreateButton == 'tag'" (click)="open(CreateTag)">
      <a class="blue-btn ms-4">+ Add</a>
    </button>


    <button type="button" class="border-0 bg-transparent" *ngIf="showCreateButton == 'user'" (click)="open(CreateUser)">
      <a class="blue-btn ms-4">+ Add</a>
    </button>

    <button type="button" class="border-0 bg-transparent" *ngIf="showCreateButton == 'tenant'"
      (click)="open(CreateTenant)">
      <a class="blue-btn ms-4">+ Add</a>
    </button>

    <button type="button" class="border-0 bg-transparent" *ngIf="showCreateButton == 'candidate-status'"
      (click)="open(CreateCandidateStatus)">
      <a class="blue-btn ms-4">+ Add</a>
    </button>

    <button type="button" class="border-0 bg-transparent" *ngIf="showCreateButton == 'client-status'"
      (click)="open(CreateClientStatus)">
      <a class="blue-btn ms-4">+ Add</a>
    </button>

  </div>

</div>
<div class="top-right-sec w-45 d-flex" *ngIf="isSearchable">

  <!-- Search Start -->
  <div class="input-group small-search me-3">
    <input type="text" class="form-control" placeholder="Search" (keyup.enter)="onSearchClick(inputsearch.value)"
      tabindex="-1" #inputsearch aria-label="Search" aria-describedby="basic-addon2">
    <div class="input-group-append rounded">
      <button class="btn" type="button" (click)="onSearchClick(inputsearch.value)"><img
          src="./assets/img/search-black.svg" alt="" ></button>
    </div>
  </div>



  <!-- Search End -->
  <a class="filter me-3" *ngIf="showCreateButton == 'candidate' || showCreateButton == 'project'"
    (click)="onFilterClick()">Filter</a>

</div>

<ng-template #CreateCandidate let-modal class="my-modal">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Candidate</h4>
    <button type="button" class="close" aria-label="Close"
      (click)="modal.dismiss('Cross click') ; this.submitted=false ; resetBackendCandidateerror()">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <div>
    <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createCandidateError">
      {{createCandidateError}}</div>
    <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createCandidateErrorUrl">
      {{createCandidateErrorUrl}}</div>
    <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
      *ngIf="createCandidateErrorProjectId">{{createCandidateErrorProjectId}}</div>

  </div>
  <div class="modal-body">
    <div class="mb-3">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" (click)="candidateTypeChange(true)" name="candidate_type"
          id="linkedIn" value="1" checked>
        <label class="form-check-label" for="linkedIn" (click)="candidateTypeChange(true)">
          From LinkedIn
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" (click)="candidateTypeChange(false)" name="candidate_type"
          id="manually" value="2">
        <label class="form-check-label" for="manually" (click)="candidateTypeChange(false)">
          Manually
        </label>
      </div>
    </div>
    <div *ngIf="linkedin_candidate">
      <form [formGroup]="CreateCandidateForm">
        <div class="mb-3">
          <label for="linkedin">LinkedIn URL</label><span class="text-danger"
            style="font-size: 14px;">&nbsp;<strong>*</strong></span>
          <input type="text" placeholder="Enter LinkedIn URL" id="linkedin" formControlName="linkedInUrl"
            class="form-control"
            [ngClass]="{ 'is-invalid': linkedin_submitted && createCandidateForm['linkedInUrl'].errors }">
          <div style="color: red; font-size: 14px;"
            *ngIf="linkedin_submitted && createCandidateForm['linkedInUrl'] && createCandidateForm['linkedInUrl'].errors && createCandidateForm['linkedInUrl'].errors['required']">
            Linkedin URL is required
          </div>

        </div>
        <div class="mb-3">
          <label for="project_main">Searches</label>
          <ng-select [items]="projectList" class="form-control form-select " placeholder="Select Search"
            bindLabel="dropdown" bindValue="id" (search)="onProjectSearch($event)" formControlName="projectId">
          </ng-select>

         
        </div>
      </form>
    </div>
    <div *ngIf="!linkedin_candidate">
      <form [formGroup]="CreateCandidateManuallyForm">
        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for="">First name (up to 16 characters)</label>
              <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
              <input type="text" placeholder="Enter first name" formControlName="firstname" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && createCandidateManuallyForm['firstname'].errors }">
              <div style="color: red; font-size: 14px;"
                *ngIf="submitted && createCandidateManuallyForm['firstname'] && createCandidateManuallyForm['firstname'].errors && createCandidateManuallyForm['firstname'].errors['required']">
                First name is required
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for="" >Last name (up to 16 characters)</label>
              <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
              <input type="text" placeholder="Enter last name" formControlName="lastname" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && createCandidateManuallyForm['lastname'].errors }">
              <div style="color: red; font-size: 14px;"
                *ngIf="submitted && createCandidateManuallyForm['lastname'] && createCandidateManuallyForm['lastname'].errors && createCandidateManuallyForm['lastname'].errors['required']">
                Last name is required
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="" >Email</label>
              <input type="text" placeholder="Enter Email" formControlName="email" class="form-control">
            
              <span style="color: red; font-size: 14px;text-align: left;" class="w-100 col-12 ps-0"
                *ngIf="createCandidateManuallyForm['email'] && createCandidateManuallyForm['email'].errors && createCandidateManuallyForm['email'].errors['pattern']">
                Invalid Email Address
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="project_main">Search</label>
              <ng-select [items]="projectList" class="form-control form-select " placeholder="Select Search"
                bindLabel="dropdown" bindValue="id" (search)="onProjectSearch($event)" (blur)="getProjectList()"
                formControlName="projectId">
              </ng-select>

             
            </div>
          </div>
        </div>
        <div *ngIf="candidate_exists" class="text-danger">
          <div style="padding:10px; font-size: 14px;padding-left: 20px;padding-bottom: 0px;" class="text-danger"
            *ngIf="!linkedin_candidate && !projectapicall && this.submitted">{{createCandidateManuallyError}}</div>

          <div *ngFor="let ce of candidate_exists">
            <div class="row">
              <div class="col-3" style="padding-left: 20px;">Name</div>
              <div class="col-9">{{ce.in_first_name}} {{ce.in_last_name}}</div>
            </div>
            <div class="row">
              <div class="col-3" style="padding-left: 20px;">Email</div>
              <div class="col-9">{{ce.in_personal_email}}</div>
            </div>
            <hr>
          </div>
          <div style="padding:10px; font-size: 14px;padding-left: 20px;padding-top: 0px;" class="text-danger"
            *ngIf="createCandidateManuallyError">Do you want to still create new?</div>
        </div>

      </form>
    </div>
  </div>


  <div class="modal-footer">
    <div class="spinner-border" *ngIf="projectapicall">
      <span class="visually-hidden">Loading...</span>
    </div>
    <button type="button" class="filter"
      (click)="modal.close('Save click') ; this.linkedin_submitted =false ; resetBackendCandidateerror() ; this.linkedin_candidate=true"
      *ngIf="!projectapicall && this.linkedin_candidate ">Cancel</button>
    <button type="button" class="filter"
      (click)="modal.close('Save click') ; this.submitted=false ; resetBackendCandidateerror() ; this.linkedin_candidate=true"
      *ngIf="!projectapicall && !this.linkedin_candidate && manually_submitted != 0 ">Cancel</button>
    <button type="button" class="blue-btn" (click)="createCandidate()"
      *ngIf="linkedin_candidate && !projectapicall">Save</button>
    <button type="button" class="blue-btn" (click)="createCandidateManually()"
      *ngIf="!linkedin_candidate && !projectapicall && manually_submitted != 0">Save</button>
    <button type="button" class="filter"
      (click)="modal.close('Save click') ; this.submitted=false ;this.manually_submitted = -1; resetBackendCandidateerror() ;resetBackendCandidateManuallyerror()"
      *ngIf="!linkedin_candidate && !projectapicall && this.submitted && manually_submitted == 0">No</button>
    <button type="button" class="blue-btn"
      (click)="this.createCandidateManuallyConfirmation = true; createCandidateManually()"
      *ngIf="!linkedin_candidate && !projectapicall && this.submitted && manually_submitted == 0">Yes</button>

  </div>


</ng-template>

<ng-template #CandidateBulkImport let-modal class="my-modal">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Candidate Import</h4>
    <button type="button" class="close" aria-label="Close"
      (click)="modal.dismiss('Cross click') ; this.submitted=false ; resetBackendCandidateerror()">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <div class="modal-body">
    <form [formGroup]="CandidateBulkImportForm" *ngIf="showFormView">
      <div class="mb-3">
        <label for="" >File</label>
        <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
        <input type="file" multiple accept=".xlsx,.csv,.xls" placeholder="Candidate File" max-file-size="10000000"
          formControlName="candidateFile" class="form-control" (change)="attachFiles($event)"
          [ngClass]="{ 'is-invalid': submitted && candidateBulkImportForm['candidateFile'].errors }">
        <div style="color: red; font-size: 14px;"
          *ngIf="submitted && candidateBulkImportForm['candidateFile'] && candidateBulkImportForm['candidateFile'].errors && candidateBulkImportForm['candidateFile'].errors['required']">
          Candidate file is required.
        </div>
      </div>
      <div class="m-1">
        <div class="bulkimport">
          <p style="padding: 10px;">
            Accepted file formats are .csv, .xls and .xlsx only. <br>
            File uploads are limited to 500 candidates. <br>
            For your convenience,<a class="sample-link" (click)="sampleImport()">download a
              sample upload file.</a>
          </p>
        </div>
      </div>
      <div class="mb-3">
        <label for="project_main">Search</label>

        <ng-select [items]="projectList" class="form-control form-select" placeholder="Select Search"
          bindLabel="dropdown" bindValue="id" (search)="onProjectSearch($event)" formControlName="searches">
        </ng-select>

      </div>
      <div class="mb-3">
        <label for="" >Tags</label>
      
        <ng-select #tagselect [items]="tagList" class="form-control form-select " placeholder="Select Tags"
          bindLabel="name" bindValue="id" (search)="onTagSearch($event)" 
          formControlName="tags" [multiple]="true">
        </ng-select>

      </div>
    </form>
    <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
      *ngIf="candidateBulkImportError && !showFormView">{{candidateBulkImportError}}</div>
    <div style="padding:10px; font-size: 14px;padding-left: 20px;white-space: pre-line" class="text-danger"
      *ngIf="bulkImpotedCandidateError && !showFormView">{{bulkImpotedCandidateError}}</div>
    <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-success"
      *ngIf="candidateBulkImportSuccess && !showFormView">{{candidateBulkImportSuccess}}</div>
    <div *ngIf="notImportedCandidates" class="text-danger">
      <div *ngFor="let ce of notImportedCandidates">
        <div class="row" *ngIf="ce.valid == false">
          <div class="col-3" style="padding-left: 20px;">Name</div>
          <div class="col-9">{{ce.in_first_name}} {{ce.in_last_name}}</div>
        </div>
        <div class="row" *ngIf="ce.valid == false">
          <div class="col-3" style="padding-left: 20px;">Message</div>
          <div class="col-9">{{ce.message}}</div>
        </div>
        <hr *ngIf="ce.valid == false">
      </div>
    </div>


  </div>


  <div class="modal-footer">
    <div class="spinner-border" *ngIf="projectapicall">
      <span class="visually-hidden">Loading...</span>
    </div>
    <button type="button" class="filter"
      (click)="modal.close('Save click') ; this.submitted=false ; resetImportCandidateForm() ; closeImport()"
      *ngIf="!projectapicall && !showFormView">Close</button>
    <button type="button" class="filter"
      (click)="modal.close('Save click') ; this.submitted=false ; resetImportCandidateForm()"
      *ngIf="!projectapicall && showFormView">{{(candidateBulkImportError =='')?'Cancel':'Close'}}</button>
    <button type="button" class="blue-btn" *ngIf="!notImportedCandidates  && !projectapicall && showFormView"
      (click)="candidateBulkImport()">Import</button>
  </div>


</ng-template>

<ng-template #CreateProject let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Search</h4>
    <button type="button" class="close" aria-label="Close"
      (click)="modal.dismiss('Cross click') ; this.submitted = false ; resetBackendProjecterror()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <form [formGroup]="CreateProjectForm">

    <div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createProjectError">
        {{createProjectError}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createProjectErrorName">
        {{createProjectErrorName}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
        *ngIf="createProjectErrorDescription">{{createProjectErrorDescription}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
        *ngIf="createProjectErrorClientname">{{createProjectErrorClientname}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createProjectErrorType">
        {{createProjectErrorType}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
        *ngIf="createProjectErrorOfficialstartdate">{{createProjectErrorOfficialstartdate}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createProjectErrorCity">
        {{createProjectErrorCity}}</div>

    </div>
    <div class="modal-body">

      <div class="mb-3">
        <label for="" >Position</label><span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
        <input type="text" placeholder="Enter Position Title" formControlName="projectName" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && createProjectForm['projectName'].errors }">
        <div style="color: red; font-size: 14px;"
          *ngIf="submitted && createProjectForm['projectName'] && createProjectForm['projectName'].errors && createProjectForm['projectName'].errors['required']">
          Position is required
        </div>
      </div>


      <div *ngIf="is_client_module_access_disabled == '0'" class="mb-3">
        <label for="">Client</label>
        <ng-select [items]="clientsList" class="form-control form-select" bindLabel="name" bindValue="id"
          (search)="onClientSearch($event)" formControlName="client" placeholder="Select Client">
        </ng-select>
      

      </div>


      <div class="mb-3">
        <label for="">Add Another User</label>
       
        <ng-select [items]="userList" class="form-control form-select" [multiple]="true" bindLabel="name" bindValue="id"
          (search)="onUserSearch($event)" formControlName="user" placeholder="Select User">
        </ng-select>
       
        <small class="text-primary">As a creator, you are automatically added in the Search.</small>
      </div>
      <div class="mb-3">
        <label for="" >Description</label>
        <textarea type="text" placeholder="Enter Description" formControlName="description"
          class="form-control"></textarea>
      </div>
      <div class="row mb-3">
        <div class="col-6">
          <label for="">Start Date</label><span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
          <input type="date" formControlName="startDate" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && createProjectForm['startDate'].errors }">
          <div style="color: red; font-size: 14px;"
            *ngIf="submitted && createProjectForm['startDate'] && createProjectForm['startDate'].errors && createProjectForm['startDate'].errors['required']">
            Start Date is required
          </div>
        </div>
       
      </div>

      <div class="row">
        <div class="col-6">
          <label for="">Type</label>
          <ng-select [items]="projectTypeList" class="form-control form-select" bindLabel="name" bindValue="id"
            placeholder="Select Type" formControlName="projectType"
            [ngClass]="{ 'is-invalid': submitted && createProjectForm['projectType'].errors }">
          </ng-select>

        </div>
        <div class="col-6">
          <label for="" >City</label>
          <input type="text" formControlName="city" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && createProjectForm['city'].errors }" placeholder="Enter City Name">
         
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="spinner-border" *ngIf="projectapicall">
        <span class="visually-hidden">Loading...</span>
      </div>
      <button type="button" class="filter"
        (click)="modal.close('Save click') ; this.submitted = false ; resetBackendProjecterror()"
        *ngIf="!projectapicall">Cancel</button>
      <button type="button" class="blue-btn" (click)="createProject()" *ngIf="!projectapicall">Save</button>
    </div>
  </form>
</ng-template>


<ng-template #CreateClient let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Client</h4>
    <button type="button" class="close" aria-label="Close"
      (click)="modal.dismiss('Cross click')  ; this.submitted = false ; resetBackendClienterror()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <form [formGroup]="CreateClientForm">
    <div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createClientError">
        {{createClientError}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createClientErrorName">
        {{createClientErrorName}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
        *ngIf="createClientErrorIndustry">{{createClientErrorIndustry}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createClientErrorCity">
        {{createClientErrorCity}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
        *ngIf="createClientErrorPrimarycontact">{{createClientErrorPrimarycontact}}</div>
    </div>
    <div class="modal-body">


      <div class="mb-3">
        <label for="" >Name</label>
        <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
        <input type="text" placeholder="Enter client name" formControlName="name" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && createClientForm['name'].errors }">
        <div style="color: red; font-size: 14px;"
          *ngIf="submitted && createClientForm['name'] && createClientForm['name'].errors && createClientForm['name'].errors['required']">
          Name is required.
        </div>
      </div>

      <!-- Account Owner -->
      <div class="mb-3">
        <label for="">Account Owner/Originator</label>
        <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
        <ng-select [items]="userList" class="form-control form-select" bindLabel="name" bindValue="id"
          (search)="onUserSearch($event)" (change)="onUserSearch($event)" formControlName="user_id"
          placeholder="Select Account Owner/Originator">
        </ng-select>
        <div style="color: red; font-size: 14px;"
          *ngIf="submitted && createClientForm['user_id'] && createClientForm['user_id'].errors && createClientForm['user_id'].errors['required']">
          Account Owner/Originator is required.
        </div>

      </div>


      



      <div class="mb-3">
        <label for="" >Industry</label>
        <input type="text" placeholder="Enter industry name" formControlName="industry" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && createClientForm['industry'].errors }">
       
      </div>

   

    </div>
  
    <div class="modal-footer">
      <div class="spinner-border" *ngIf="projectapicall">
        <span class="visually-hidden">Loading...</span>
      </div>
      <button type="button" class="filter"
        (click)="modal.close('Save click')  ; this.submitted = false ; resetBackendClienterror()"
        *ngIf="!projectapicall">Cancel</button>
      <button type="button" class="blue-btn ms-" (click)="createClients()" *ngIf="!projectapicall">Save</button>
    </div>
  </form>
</ng-template>



<ng-template #CreateTag let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Tag</h4>
    <button type="button" class="close" aria-label="Close"
      (click)="modal.dismiss('Cross click') ; this.submitted = false ; resetBackendTagerror()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <form [formGroup]="CreateTagForm">
    <div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createTagError">
        {{createTagError}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createTagErrorName">
        {{createTagErrorName}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
        *ngIf="createTagErrorDescription">{{createTagErrorDescription}}</div>
    </div>
    <div class="modal-body">

      <div class="mb-3">
        <label for="">Name</label>
        <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
        <input type="text" placeholder="Enter tag name" formControlName="name" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && createTagForm['name'].errors }">
        <div style="color: red; font-size: 14px;"
          *ngIf="submitted && createTagForm['name'] && createTagForm['name'].errors && createTagForm['name'].errors['required']">
          Name is required.
        </div>
      </div>


      <div class="mb-3">
        <label for="">Description</label>
        <input type="text" placeholder="Enter description" formControlName="description" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && createTagForm['description'].errors }">

        <div style="color: red; font-size: 14px; text-align: left" class="w-100 col-12 ps-0"
          *ngIf="submitted && (createTagForm['description'].errors?.['minlength'] || createTagForm['description'].errors?.['maxlength'])">
          Description must be between 1 - 15 characters.
        </div>

        <div style="color: red; font-size: 14px;"
          *ngIf="submitted && createTagForm['description'].errors?.['required']">
          Description is required.
        </div>

        <small class="text-primary">Max 15 characters.</small>
      </div>

   
    </div>


    <div class="modal-footer">
      <div class="spinner-border" *ngIf="projectapicall">
        <span class="visually-hidden">Loading...</span>
      </div>
      <button type="button" class="filter"
        (click)="modal.close('Save click') ; this.submitted = false ; resetBackendTagerror()"
        *ngIf="!projectapicall">Cancel</button>
      <button type="button" class="blue-btn" (click)="createTags()" *ngIf="!projectapicall">Save</button>

    </div>
  </form>
</ng-template>

<ng-template #CreateUser let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add User</h4>
    <button type="button" class="close" aria-label="Close"
      (click)="modal.dismiss('Cross click') ; this.submitted = false ; resetBackendUsererror()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <form [formGroup]="CreateUserForm">
    <div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createUserError">
        {{createUserError}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createUserErrorName">
        {{createUserErrorName}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createUserErrorEmail">
        {{createUserErrorEmail}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createUserErrorPassword">
        {{createUserErrorPassword}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createUserErrorRoleid">
        {{createUserErrorRoleid}}</div>
    </div>
    <div class="modal-body">
      <div class="mb-3">
        <label for="" >Name</label>
        <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
        <input type="text" placeholder="Enter name" formControlName="name" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && createUserForm['name'].errors }">
        <div style="color: red; font-size: 14px;"
          *ngIf="submitted && createUserForm['name'] && createUserForm['name'].errors && createUserForm['name'].errors['required']">
          Name is required.
        </div>
      </div>
      <div class="mb-3">
        <label for="" >Email</label>
        <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
        <input type="email" placeholder="Enter email" formControlName="email" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && createUserForm['email'].errors }">
        <div style="color: red; font-size: 14px;"
          *ngIf="submitted && createUserForm['email'] && createUserForm['email'].errors && createUserForm['email'].errors['required']">
          Email address is required.
        </div>
        <span style="color: red; font-size: 14px;text-align: left;" class="w-100 col-12 ps-0"
          *ngIf="createUserForm['email'] && createUserForm['email'].errors && createUserForm['email'].errors['pattern']">
          Invalid Email Address
        </span>
      </div>

      <div class="password-container mb-3">
        <label for="" >Password</label>
        <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
        <div class="password-container">
          <input type="password" class="form-control" placeholder="Enter password" formControlName="password"
            [ngClass]="{ 'is-invalid': submitted && createUserForm['password'].errors }" *ngIf="!showPassword" />
          <input type="text" class="form-control" placeholder="Enter password" formControlName="password"
            [ngClass]="{ 'is-invalid': submitted && createUserForm['password'].errors }" *ngIf="showPassword" />
          <em class="fa fa-eye" (click)="togglePassword()" *ngIf="showPassword"
            style="margin-right: 20px !important;"></em>
          <em class="fa fa-eye-slash" (click)="togglePassword()" *ngIf="!showPassword"
            style="margin-right: 20px !important;"></em>
        </div>
        <div style="display:block" class="conn">
          <span style="color: red; font-size: 14px;text-align: left" class="w-100 col-12 ps-0"
            *ngIf="submitted && createUserForm['password'] && createUserForm['password'].errors && createUserForm['password'].errors['required']">
            Password is required.
          </span>
          <span style="color: red; font-size: 14px;text-align: left" class="w-100 col-12 ps-0"
            *ngIf="createUserForm['password'].errors && (createUserForm['password'].errors['minlength'] || createUserForm['password'].errors['maxlength'])">
            Password must be between 6 - 16 characters.
          </span>
         
        </div>

      </div>


     


      <div *ngIf="currentTenantId !='0'">
        <label for="" > Role</label>
        <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
        <select name="" id="" [(ngModel)]="selectedOption" class="form-select form-control" formControlName="role_id"
          placeholder="Select Role" [ngClass]="{ 'is-invalid': submitted && createUserForm['role_id'].errors }">
          <option value="1">Admin</option>
          <option value="2">User</option>
        </select>
        <div style="color: red; font-size: 14px;"
          *ngIf="submitted && createUserForm['role_id'] && createUserForm['role_id'].errors && createUserForm['role_id'].errors['required']">
          Role is required.
        </div>
      </div>


    </div>


    <div class="modal-footer">
      <div class="spinner-border" *ngIf="projectapicall">
        <span class="visually-hidden">Loading...</span>
      </div>
      <button type="button" class="filter"
        (click)="modal.close('Save click') ; this.submitted = false ; resetBackendUsererror()"
        *ngIf="!projectapicall">Cancel</button>
      <button type="button" class="blue-btn" (click)="createUsers()" *ngIf="!projectapicall">Save</button>
    </div>
  </form>
</ng-template>

<ng-template #CreateTenant let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Tenant</h4>
    <button type="button" class="close" aria-label="Close"
      (click)="modal.dismiss('Cross click') ; this.submitted = false ; resetBackendTenanterror()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <form [formGroup]="CreateTenantForm" *ngIf="showtenantform">
    <div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createTenantError">
        {{createTenantError}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createTenantErrorId">
        {{createTenantErrorId}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createTenantErrorName">
        {{createTenantErrorName}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
        *ngIf="createTenantErroruserName">{{createTenantErroruserName}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
        *ngIf="createTenantErroruserEmail">{{createTenantErroruserEmail}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
        *ngIf="createTenantErroruserPassword">{{createTenantErroruserPassword}}</div>
    </div>
    <div class="modal-body">
      <div style="display: flex;">
        <h6 id="modal-basic-title" class="modal-title">
          Tenant Information
        </h6>
      </div>

      <div class="row" style="margin-top:3px;">
        <div>
          <label for="" >Tenant Logo</label>
          <button type="button" class="blue-btn" (click)="selectFile.click()" *ngIf="logoBox"
            style="font-size: 14px !important;float: right;">Change</button>
        </div>
        <input type="file" id="file-input" style="display: none;" accept="image/*" multiple #selectFile
          max-file-size="1024" formControlName="logo" class="form-control" (change)="attachLogoFiles($event)" />
      </div>
      <div class="row" style="margin-top: 10px;">
        <div *ngIf="rectangleBox" id="panelsStayOpen-collapsesix"
          class="accordion-collapse collapse show scrolldocument" aria-labelledby="panelsStayOpen-headingsix">
          <div class="accordion-body clientlogobg file">
            <label for="file-input" (click)="selectFile.click()">Choose File</label>
            <input type="file" id="file-input" style="display: none;" accept="image/*" multiple #selectFile
              max-file-size="1024" formControlName="logo" class="form-control" (change)="attachLogoFiles($event)" />
          </div>
        </div>
        <div class="col-lg-12 row" style="margin-top: 10px;" *ngIf="logoBox">
          <div class="col-12 " style="padding-right: 0px;">
            <img [src]="url" *ngIf="url !== null" height="120" class="clientlogobgimg img-fluid" alt="" >
          </div>

        </div>
      </div>

      <div class="mb-3" style="margin-top: 3px;">
        <label for="" >Tenant Name</label>
        <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
        <input type="text" placeholder="Enter name" formControlName="name" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && createTenantForm['name'].errors }">
        <div style="color: red; font-size: 14px;"
          *ngIf="submitted && createTenantForm['name'] && createTenantForm['name'].errors && createTenantForm['name'].errors['required']">
          Name is required.
        </div>
      </div>

      <div class="mb-3" style="margin-top: 0px;">
        <label for="" >Unique ID</label>
        <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
        <input type="text" min="3" max="8" placeholder="Enter Unique ID" formControlName="id" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && createTenantForm['id'].errors }">
        <div style="color: red; font-size: 14px;"
          *ngIf="submitted && createTenantForm['id'] && createTenantForm['id'].errors && createTenantForm['id'].errors['required']">
          Unique ID is required.
        </div>
        <div style="color: red; font-size: 14px;"
          *ngIf="createTenantForm['id'].errors && (createTenantForm['id'].errors['minlength'] || createTenantForm['id'].errors['maxlength'])">
          Unique ID must be between 3 - 8 characters.
        </div>
        <div class="col-lg-12">
          <small class="text-primary">Enter short name between 3-8 characters without any space.</small>
        </div>

      </div>



      <div class="mb-3">
        <label for="" >Description</label>
        <textarea type="text" placeholder="Enter description" formControlName="description" class="form-control"
          rows="2"></textarea>
      </div>

      <div class="mb-3">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="disable_access_to_client_module">
          <label class="form-check-label" for="disable_access_to_client_module">
            &nbsp;Disable access to 'Client' module
          </label>
        </div>
        <div class="col-lg-12">
          <small class="text-primary">Select this option for internal tenants</small>
        </div>
      </div>

      <!-- Enable public job posting  -->
      <div class="mb-3">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="is_public_search">
          <label class="form-check-label" for="is_public_search">
            &nbsp;Enable public job posting
          </label>
        </div>
        <div class="col-lg-12">
          <small class="text-primary">Select this option for job posting</small>
        </div>
      </div>


      <div style="display: flex;">
        <h6 id="modal-basic-title" class="modal-title">
          Admin User Information
        </h6>
      </div>
      <div class="mb-3" style="margin-top: 3px;">
        <label for=""> Name</label>
        <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
        <input type="text" placeholder="Enter name" formControlName="user_name" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && createTenantForm['user_name'].errors }">
        <div style="color: red; font-size: 14px;"
          *ngIf="submitted && createTenantForm['user_name'] && createTenantForm['user_name'].errors && createTenantForm['user_name'].errors['required']">
          Name is required.
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="mb-3">
            <label for="">Email</label>
            <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
            <input type="email" placeholder="Enter email" formControlName="user_email" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && createTenantForm['user_email'].errors }">
            <div style="color: red; font-size: 14px;"
              *ngIf="submitted && createTenantForm['user_email'] && createTenantForm['user_email'].errors && createTenantForm['user_email'].errors['required']">
              Email address is required.
            </div>
            <span style="color: red; font-size: 14px;text-align: left;" class="w-100 col-12 ps-0"
              *ngIf="createTenantForm['user_email'] && createTenantForm['user_email'].errors && createTenantForm['user_email'].errors['pattern']">
              Invalid Email Address
            </span>
          </div>
        </div>
        <div class="col-6">
          <div style="width: 221px !important;" class="password-container mb-3">
            <label for="">Password</label>
            <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
            <div class="password-container" style="padding:10;width:221px;">
              <input type="password" style="padding:10;width:221px;" class="form-control" placeholder="Password"
                formControlName="user_password"
                [ngClass]="{ 'is-invalid': submitted && createTenantForm['user_password'].errors }"
                *ngIf="!showPassword" />
              <input type="text" style="padding:10;width:221px;" class="form-control" placeholder="Password"
                formControlName="user_password"
                [ngClass]="{ 'is-invalid': submitted && createTenantForm['user_password'].errors }"
                *ngIf="showPassword" />
              <em class="fa fa-eye" (click)="togglePassword()" *ngIf="showPassword"
                style="margin-right: 20px !important;"></em>
              <em class="fa fa-eye-slash" (click)="togglePassword()" *ngIf="!showPassword"
                style="margin-right: 20px !important;"></em>
            </div>
            <div style="padding:10;width:221px;display:block" class="conn">
              <span style="color: red; font-size: 14px;text-align: left;width:221px !important;"
                class="w-100 col-12 ps-0"
                *ngIf="submitted && createTenantForm['user_password'] && createTenantForm['user_password'].errors && createTenantForm['user_password'].errors['required']">
                Password is required.
              </span>
              <span style="color: red; font-size: 14px;text-align: left;width:221px !important;"
                class="w-100 col-12 ps-0"
                *ngIf="createTenantForm['user_password'].errors && (createTenantForm['user_password'].errors['minlength'] || createTenantForm['user_password'].errors['maxlength'])">
                Password must be between 6 - 16 characters.
              </span>
             
            </div>

          </div>

        </div>

      </div>

    </div>


    <div class="modal-footer">
      <div class="spinner-border" *ngIf="projectapicall">
        <span class="visually-hidden">Loading...</span>
      </div>
      <button type="button" class="filter"
        (click)="modal.close('Save click') ; this.submitted = false ; resetBackendTenanterror()"
        *ngIf="!projectapicall">Cancel</button>
      <button type="button" class="blue-btn" (click)="createTenanats()" *ngIf="!projectapicall">Save</button>
    </div>
  </form>
  <div *ngIf="!showtenantform">
    <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-success">{{tenantSuccessMessage}}</div>
    <p class="ms-1" style="padding: 10px;color: rgb(48, 112, 48);">Tenant creation is in progress and will be created in
      some time.</p>
  </div>
</ng-template>

<ng-template #CreateCandidateStatus let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Candidate Status</h4>
    <button type="button" class="close" aria-label="Close"
      (click)="modal.dismiss('Cross click') ; this.submitted = false ; resetBackendCSerror()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <form [formGroup]="CreateCandidateStatusForm">
    <div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createCSError">
        {{createCSError}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createCSErrorName">
        {{createCSErrorName}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
        *ngIf="createCSErrorDescription">{{createCSErrorDescription}}</div>
    </div>
    <div class="modal-body">
      <div class="mb-3">
        <label for="">Name</label>
        <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
        <input type="text" placeholder="Enter candidate status " formControlName="name" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && createCandidateStatusForm['name'].errors }">
        <div style="color: red; font-size: 14px;"
          *ngIf="submitted && createCandidateStatusForm['name'] && createCandidateStatusForm['name'].errors && createCandidateStatusForm['name'].errors['required']">
          Status name is required.
        </div>
      </div>
      <div class="mb-3">
        <label for="">Description</label>
        <textarea type="text" placeholder="Enter description" formControlName="description" class="form-control"
          rows="3"></textarea>
      </div>
    
    </div>


    <div class="modal-footer">
      <div class="spinner-border" *ngIf="csapicall">
        <span class="visually-hidden">Loading...</span>
      </div>
      <button type="button" class="filter"
        (click)="modal.close('Save click') ; this.submitted = false ; resetBackendCSerror()"
        *ngIf="!csapicall">Cancel</button>
      <button type="button" class="blue-btn" (click)="createCandidateStatus()" *ngIf="!csapicall">Save</button>

    </div>
  </form>
</ng-template>

<ng-template #CreateClientStatus let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Client Stage</h4>
    <button type="button" class="close" aria-label="Close"
      (click)="modal.dismiss('Cross click') ; this.submitted = false ; resetBackendCSerror()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <form [formGroup]="CreateClientStatusForm">
    <div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createClSError">
        {{createClSError}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createClSErrorName">
        {{createClSErrorName}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
        *ngIf="createClSErrorDescription">{{createClSErrorDescription}}</div>
    </div>
    <div class="modal-body">
      <div class="mb-3">
        <label for="">Name</label>
        <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
        <input type="text" placeholder="Enter client status " formControlName="name" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && createClientStatusForm['name'].errors }">
        <div style="color: red; font-size: 14px;"
          *ngIf="submitted && createClientStatusForm['name'] && createClientStatusForm['name'].errors && createClientStatusForm['name'].errors['required']">
          Status name is required.
        </div>
      </div>
      <div class="mb-3">
        <label for="">Description</label>
        <textarea type="text" placeholder="Enter description" formControlName="description" class="form-control"
          rows="3"></textarea>
      </div>
   
    </div>


    <div class="modal-footer">
      <div class="spinner-border" *ngIf="csapicall">
        <span class="visually-hidden">Loading...</span>
      </div>
      <button type="button" class="filter"
        (click)="modal.close('Save click') ; this.submitted = false ; resetBackendClSerror()"
        *ngIf="!csapicall">Cancel</button>
      <button type="button" class="blue-btn" (click)="createClientStatus()" *ngIf="!clsapicall">Save</button>

    </div>
  </form>
</ng-template>




<!-- Add Job Posting -->
<ng-template #AddJobPosting let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Job Posting</h4>
    <button type="button" class="close" aria-label="Close"
      (click)="modal.dismiss('Cross click') ; this.submitted = false ; resetBackendProjecterror()">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <form [formGroup]="AddJobPostingForm">

    <div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createProjectError">
        {{createProjectError}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createProjectErrorName">
        {{createProjectErrorName}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
        *ngIf="createProjectErrorDescription">{{createProjectErrorDescription}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
        *ngIf="createProjectErrorClientname">{{createProjectErrorClientname}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createProjectErrorCity">
        {{createProjectErrorCity}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
        *ngIf="createProjectErroris_public">
        {{createProjectErroris_public}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
        *ngIf="createProjectErrorClientname">{{createProjectErrorClientname}}</div>
    </div>

    <div class="modal-body">

      <div class="row">

        <div class="col-md-6 mb-3">
          <label for="" >Position</label><span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
          <input type="text" placeholder="Enter Position Title" formControlName="projectName" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && addJobForm['projectName'].errors }">
          <div style="color: red; font-size: 14px;"
            *ngIf="submitted && addJobForm['projectName'] && addJobForm['projectName'].errors && addJobForm['projectName'].errors['required']">
            Position is required
          </div>
        </div>

        <div class="col-6">
          <label for=""> Status</label>
          <ng-select [items]="statusofprojectList" class="form-control form-select" placeholder="Select type"
            bindLabel="name" bindValue="id" formControlName="project_status_id">
          </ng-select>
        </div>


      </div>

      <div class="row">

        <div *ngIf="is_client_module_access_disabled == '0'" class="col-4">
          <div class="mb-3">
            <label for="">Client</label>
            <ng-select [items]="clientsList" class="form-control form-select" bindLabel="name" bindValue="id"
              (search)="onClientSearch($event)" (blur)="getClientsList()" (change)="onClientSearch($event)"
              formControlName="client" placeholder="Select Client">
            </ng-select>
          </div>
        </div>

        <div class="col-4">
          <div class="mb-3">
            <label for="">City</label>
            <input type="text" placeholder="Enter city name" formControlName="city" maxlength="30" class="form-control">
          </div>
        </div>

        <div class="col-4">
          <div class="mb-3">
            <label for="">State</label>
            <ng-select [items]="stateList" class="form-control form-select" bindLabel="name" bindValue="name"
              formControlName="state" placeholder="Select state">
            </ng-select>
          </div>
        </div>

      </div>

      <div class="row">

        <div class="mb-3">
          <label for="">Description</label>
          <angular-editor formControlName="description" [config]="config"></angular-editor>
        </div>
      </div>

    </div>

    <div class="modal-footer">
      <div class="spinner-border" *ngIf="projectapicall">
        <span class="visually-hidden">Loading...</span>
      </div>
      <button type="button" class="filter"
        (click)="modal.close('Save click') ; this.submitted = false ; resetBackendProjecterror()"
        *ngIf="!projectapicall">Cancel</button>
      <button type="button" class="blue-btn" (click)="createJobPosting()" *ngIf="!projectapicall">Save</button>
    </div>


  </form>

</ng-template>