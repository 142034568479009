import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private httpClient: HttpClient) { }

  create(data: any): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}projects`, data);
  }

  listAll(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}projects`);
  }

  // listAll(data?: any): Observable<any> {
  //   return this.httpClient.get(`${environment.apiUrl}projects?is_public=` + data.is_public);
  // }

  listSearch(data?: any): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}projects?search=` + data.search);
  }

  listTypeAll(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}project-types`);
  }

  listStatus(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}project-statuses`);
  }

  listStateAll(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}states`);
  }

  listTypeSearch(data?: any): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}project-types?search=` + data.search);
  }
  projectDetail(id: any) {
    return this.httpClient.get(`${environment.apiUrl}projects/` + id);
  }

  getProjectData(data?: any): Observable<any> {
    // console.log(data,"in servce")
    return this.httpClient.get(`${environment.apiUrl}projects/` + data.id);
  }


  updateProjectData(id: number, data: any): Observable<any> {
    return this.httpClient.put(`${environment.apiUrl}projects/` + id, data);
  }

  deleteProjectData(id: number): Observable<any> {
    return this.httpClient.delete(`${environment.apiUrl}projects/` + id);
  }


  searchProjectList(data?: any): Observable<any> {
    let get_params: string = '';

    if (data.is_public !== undefined && data.is_public !== null) {
      if (get_params !== '') {
        get_params += '&';
      }
      get_params += 'is_public=' + data.is_public;
    }

    if (data.search) {
      if (get_params != '') {
        get_params += `&`
      }
      get_params += `search=` + data.search;
    }
    return this.httpClient.get(`${environment.apiUrl}projects?` + get_params);
  }

  pinProject(id: any) {
    return this.httpClient.post(`${environment.apiUrl}projects/` + id + `/pin`, id);
  }
  unpinProject(id: any) {
    return this.httpClient.post(`${environment.apiUrl}projects/` + id + `/unpin`, id);
  }


  searchProjectwiseList(data?: any) {
    let get_params: string = '';
    if (data && data.search) {
      get_params += `search=` + data.search;
    }
    return this.httpClient.get(`${environment.apiUrl}projects?` + get_params);
  }

  exportProject(id: any) {
    return this.httpClient.get(`${environment.apiUrl}projects/` + id + "/export");
  }

  searchCandidateList(data?: any) {
    let get_params: string = '';
    if (data && data.search) {
      get_params += `search=` + data.search;
    }
    return this.httpClient.get(`${environment.apiUrl}candidates?` + get_params);
  }

  listByFilter(data?: any): Observable<any> {


    let get_params: string = '';
    if (data.sort_field && data.sort_method) {
      get_params += `sort_field=` + data.sort_field + `&sort_method= ` + data.sort_method;
    }
    if (data.page) {
      if (get_params != '') {
        get_params += `&`
      }
      get_params += `page=` + data.page;
    }
    if (data.user_id) {
      if (get_params != '') {
        get_params += `&`
      }
      get_params += `user_id=` + data.user_id;
    }
    if (data.search) {
      if (get_params != '') {
        get_params += `&`
      }
      get_params += `search=` + data.search;
    }
    if (data.apply_filter) {
      if (get_params != '') {
        get_params += `&`
      }
      get_params += data.apply_filter;
    }

    // if (data.is_public) {
    //   if (get_params != '') {
    //     get_params += `&`
    //   }
    //   get_params += `is_public=` + data.is_public;
    // }

    if (data.hasOwnProperty('is_public')) {
      if (get_params != '') {
        get_params += `&`
      }
      get_params += `is_public=` + data.is_public;
    }


    return this.httpClient.get(`${environment.apiUrl}projects?` + get_params);
  }

  candidateListByFilter(id: number, data?: any): Observable<any> {
    let get_params: string = '';
    if (data.sort_field && data.sort_method) {
      get_params += `sort_field=` + data.sort_field + `&sort_method= ` + data.sort_method;
    }
    if (data.page) {
      if (get_params != '') {
        get_params += `&`
      }
      get_params += `page=` + data.page;
    }
    if (data.search) {
      if (get_params != '') {
        get_params += `&`
      }
      get_params += `search=` + data.search;
    }
    if (data.apply_filter) {
      if (get_params != '') {
        get_params += `&`
      }
      get_params += data.apply_filter;
    }

    return this.httpClient.get(`${environment.apiUrl}projects/` + id + `/candidates?` + get_params);
  }

  statusofProjectList(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}project-statuses`);
  }

  createDocument(id: number, data: any) {
    return this.httpClient.post(`${environment.apiUrl}projects/` + id + "/documents", data);
  }

  deleteDocument(id: number, documentId: any) {
    return this.httpClient.delete(`${environment.apiUrl}projects/` + id + "/documents/" + documentId);
  }

  getDocumentData(id: number): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}projects/` + id);
  }

  addUsersToSearch(id: number, data: any): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}projects/` + id + '/users/attach', data);
  }

}
